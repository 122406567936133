import React from 'react'

import CustomQuestionCard from './CustomQuestionCard'
import CustomQuestionMultipleChoice from './CustomQuestionMultipleChoice'
import CustomQuestionEmojiScale from './CustomQuestionEmojiScale'
import CustomQuestionBoolean from './CustomQuestionBoolean'
import CustomQuestionNps from './CustomQuestionNps'

const CustomQuestion = ({ question, isEventOwner }) => {
  const renderCustomQuestionContent = () => {
    switch (question.question_type) {
      case 'MULTIPLE_CHOICE':
        return <CustomQuestionMultipleChoice question={question} />
      case 'EMOJI_SCALE':
        return <CustomQuestionEmojiScale question={question} />
      case 'BOOLEAN':
        return <CustomQuestionBoolean question={question} />
      case 'NPS':
        return <CustomQuestionNps question={question} />
      default:
        return null
    }
  }

  return (
    <CustomQuestionCard question={question} isEventOwner={isEventOwner}>
      {renderCustomQuestionContent()}
    </CustomQuestionCard>
  )
}

export default CustomQuestion
