import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import YouTube from 'react-youtube'
import { styled } from '@mui/system'
import { Dialog, DialogContent, Box, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as PlusIcon } from '../../icons/plusIcon.svg'
import { ReactComponent as CodesIcon } from '../../icons/codesIcon_16x16.svg'
import { ReactComponent as MostUsedCodeIcon } from '../../icons/sunRiseIcon_36x36.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import { processWithFallback } from '../common/helpers'

import { initialOfferState } from '../../constants/codes/initialOfferStates'
import SurveyOffersSlide from '../dashboard/SurveyOffersSlide'
import CodesTable from './CodesTable'
import PageContainer from '../common/PageContainer'
import PageHeader from '../common/PageHeader'
import StatsSummaryHeader from '../common/StatsSummaryHeader'
import TableActions from '../common/TableActions'
import DownloadableQrCode from '../common/DownloadableQrCode'
import ConfirmDialog from '../common/ConfirmDialog'
import { Notification } from '../common/Notification'

import {
  usePermissionHelper,
  OFFER_CODE_LIMIT,
} from '../../utils/permission_helper'
import { Typography } from '@material-ui/core'

const VideoDialogContent = styled(DialogContent)({
  minHeight: '600px',
  padding: 0,
})

const VideoContainer = styled('div')({
  height: '600px',
})

const initialConfirmProps = {
  title: '',
  onConfirm: () => {},
  onCancel: () => {},
  children: '',
}

const CodesParent = () => {
  const [offers, setOffers] = useState([])
  const [offer, setOffer] = useState(initialOfferState)
  const [surveyLink, setSurveyLink] = useState('')
  const [mostUsedCode, setMostUsedCode] = useState('')
  const [loading, setLoading] = useState(true)
  const [slideDownloadOpen, setSlideDownloadOpen] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [qrDownloadOpen, setQrDownloadOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)
  const history = useHistory()

  const { authData, setNotification, updatePermission } = useContext(
    AuthenticationContext
  )
  const { user } = authData
  const {
    membership: { isTrialing, plan, shouldShowUpgrades },
  } = user

  const { findPermission } = usePermissionHelper()

  const defaultCode = {
    name: 'This is a quick start, uneditable code that skips bonuses and uses all Talkadot defaults.',
    code: 'TALK',
    createdAt: null,
  }

  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const res = await Api.getCodes()

        if (!res.errors) {
          setLoading(false)
          setOffers([defaultCode, ...res.offers])
          setSurveyLink(res.shortlink)
          setMostUsedCode(res.mostUsedCode?.toUpperCase())
        } else {
          throw res.errors
        }
      } catch (error) {
        setLoading(false)
        setNotification(error, 'error')
      }
    }

    fetchCodes()
  }, [])

  const handleEdit = (offer) => {
    history.push(`/codes/edit/${offer.id}`)
  }

  const handleDuplicate = async (offerId) => {
    try {
      setLoading(true)
      let res
      res = await Api.duplicateCode(offerId)

      if (!res.errors) {
        updatePermission({
          ...codePermission,
          consumed: codePermission.consumed + 1,
        })

        history.push(`/codes/edit/${res.id}`)

        setNotification('Code successfully duplicated', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
      setLoading(false)
    }
  }

  const validateOffer = (offer) => {
    const { selectedBonusType, link, downloadUrl } = offer

    if (
      (selectedBonusType === 'link' && !link) ||
      (selectedBonusType === 'file' && !downloadUrl)
    ) {
      setConfirmProps({
        variant: 'info',
        hideDangerMessage: true,
        onCancel: () => {},
        showCancel: false,
        title: 'You are missing a bonus',
        buttonText: 'Add bonus',
        buttonColor: 'primary',
        textColor: 'white',
        onConfirm: () => {
          setConfirmDialogOpen(false)
          history.push(`/codes/edit/${offer.id}`)
        },
        children: (
          <Typography variant="body1">
            You have indicated you want to give away a bonus but have not
            uploaded a link or file. Please add a bonus before proceeding or the
            select the "I do not want to give away anything" option.
          </Typography>
        ),
      })
      setConfirmDialogOpen(true)

      return false
    }

    return true
  }

  const handleOpenSlideDownload = (offer) => {
    if (!validateOffer(offer)) {
      return
    }

    setOffer(offer)
    setSlideDownloadOpen(true)
  }

  const handleCloseSlideDownload = () => {
    setSlideDownloadOpen(false)
    // use setTimeout to allow the modal to close before resetting the offer
    setTimeout(() => {
      setOffer(initialOfferState)
    }, 500)
  }

  const handleDownloadQrCode = (offer) => {
    if (!validateOffer(offer)) {
      return
    }

    setOffer(offer)
    setQrDownloadOpen(true)
  }

  const handleCloseQrDownload = () => {
    setQrDownloadOpen(false)
    setTimeout(() => {
      setOffer(initialOfferState)
    }, 500)
  }

  const handleVideoModalClose = () => {
    setVideoModalOpen(false)
    setVideoId('')
  }

  const videoOpts = {
    playerVars: {
      autoplay: 1,
    },
    width: '100%',
    height: '100%',
  }

  const codePermission = findPermission('OFFER_CODE_LIMIT')

  const { limit, unlimitedUsage, isBelowLimit } = codePermission

  const codesConsumed = loading ? 0 : offers.length - 1

  const showUpgradeButton =
    isTrialing || (!unlimitedUsage && shouldShowUpgrades)

  const statsSummaryItems = [
    {
      label: 'Total Codes',
      value: processWithFallback(codesConsumed, (val) =>
        unlimitedUsage ? val : `${val}/${limit}`
      ),
      icon: <CodesIcon />,
      svgType: 'fill',
      showUpgradeButton: showUpgradeButton,
      upgradeButtonText: 'Upgrade for more codes!',
    },
    {
      label: 'Most Used',
      value: mostUsedCode,
      icon: <MostUsedCodeIcon />,
      svgType: 'stroke',
    },
  ]

  return (
    <PageContainer>
      <PageHeader
        header="Your Talkadot Codes"
        showUpgradeButton={showUpgradeButton}
      />
      {!loading && isTrialing && (
        <>
          <Notification variant="redWarning">
            You are currently on a free trial of {plan.name} which has a code
            limit of <b>{unlimitedUsage ? 'unlimited' : limit}</b>.{' '}
            <Link to="/account/billing">Upgrade</Link> to keep this limit before
            your trial ends!
          </Notification>
        </>
      )}
      {!loading && offers?.length < 2 && (
        <Notification hideClose={true} variant="greyWarning">
          <Typography>
            <b>Talkadot codes are used in order to:</b>
          </Typography>
          <Typography>1. Configure the questions your audience sees</Typography>
          <Typography>
            2. Set up any bonuses, offers or downloads for your audience
          </Typography>
        </Notification>
      )}
      <StatsSummaryHeader statItems={statsSummaryItems} />
      <TableActions
        ctaOpts={{
          onClick: () => {
            history.push('/codes/edit')
          },
          text: 'Create a new code',
          icon: <PlusIcon />,
        }}
      />
      <CodesTable
        offers={offers}
        setOffers={setOffers}
        loading={loading}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        canDuplicate={isBelowLimit()}
        handleOpenSlideDownload={handleOpenSlideDownload}
        handleDownloadQrCode={handleDownloadQrCode}
      />
      <Dialog
        open={slideDownloadOpen}
        fullwidth="true"
        maxWidth="xl"
        sx={{ minWidth: '960px' }}
        onClose={handleCloseSlideDownload}>
        <CloseIcon
          className="modal-close-icon"
          sx={{ fill: 'white' }}
          onClick={handleCloseSlideDownload}
        />
        <SurveyOffersSlide code={offer.code} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={videoModalOpen}
        onClose={handleVideoModalClose}>
        <VideoDialogContent>
          <VideoContainer>
            <YouTube
              videoId={videoId}
              opts={videoOpts}
              style={{ width: '100%', height: '100%' }}
            />
          </VideoContainer>
        </VideoDialogContent>
      </Dialog>
      <Dialog
        open={qrDownloadOpen}
        fullwidth="true"
        maxWidth="xl"
        onClose={handleCloseQrDownload}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={handleCloseQrDownload}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent style={{ padding: '30px' }}>
          <DownloadableQrCode code={offer.code} surveyLink={surveyLink} />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        {...confirmProps}
      />
    </PageContainer>
  )
}

export default CodesParent
