import { useContext } from 'react'
import { AuthenticationContext } from '../features/authentication/authenticationContext'
import { GroupContext } from '../features/groups/groupContext'

// These constants should match the Permission Model in the backend
export const LEADS_LIMIT = 'LEADS_LIMIT'
export const LEAD_DOWNLOADS = 'LEAD_DOWNLOADS'
export const OFFER_CODE_LIMIT = 'OFFER_CODE_LIMIT'
export const NO_TALKADOT_BRANDING = 'NO_TALKADOT_BRANDING'
export const CUSTOMIZATIONS = 'CUSTOMIZATIONS'
export const EMAIL_CAMPAIGNS = 'EMAIL_CAMPAIGNS'
export const TEAMS = 'TEAMS'
export const INTEGRATIONS = 'INTEGRATIONS'
export const AI_EVENT_SUMMARY = 'AI_EVENT_SUMMARY'

// granular permissions with limits
export const CUSTOM_QUESTION_LIMIT = 'CUSTOM_QUESTION_LIMIT'

export const PRO_TIER = 'PRO_TIER'
export const LITE_TIER = 'LITE_TIER'
export const ELITE_TIER = 'ELITE_TIER'

const GRANULAR_LIMIT_PERMISSIONS = [CUSTOM_QUESTION_LIMIT]

const stateLoadingPermission = {
  enabled: false,
  limit: 0,
  consumed: 0,
  unlimitedUsage: false,
}

export function usePermissionHelper() {
  const {
    authData: {
      user: {
        isAssociate,
        membership: {
          plan: { price, planTier },
          permissions,
        },
      },
      surveyCustomQuestionCount,
    },
  } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const canToggleTalkadotBranding = () => {
    return findPermission(NO_TALKADOT_BRANDING).enabled
  }

  const canAddCustomQuestion = () => {
    return findPermission(CUSTOMIZATIONS).enabled
  }

  const canUseIntegrations = () => {
    return findPermission(INTEGRATIONS).enabled
  }

  const canDownloadLeads = () => {
    return findPermission(LEAD_DOWNLOADS).enabled
  }

  const canUseAIEventSummary = () => {
    return findPermission(AI_EVENT_SUMMARY).enabled
  }

  const canDownloadEventLeads = () => {
    // Additional gate of requiring a paid plan (Lite plans cannot use it)
    return (
      _isUsingChapterFeature() ||
      (onPaidPlan() && findPermission(LEAD_DOWNLOADS).enabled)
    )
  }

  const canUseEmailCampaigns = () => {
    return findPermission(EMAIL_CAMPAIGNS).enabled
  }

  const canCreateSurveyOffer = (currentOfferCount) => {
    const permission = findPermission(OFFER_CODE_LIMIT)

    return permission.unlimitedUsage || permission.limit > currentOfferCount
  }

  const canAccessTeams = () => {
    const permission = findPermission(TEAMS)

    return permission.enabled && !isAssociate
  }

  const canAccessAction = (actionTier) => {
    if (!actionTier) {
      return true
    }

    return planTier >= actionTier
  }

  const canReorderQuestions = () => {
    const permission = findPermission(CUSTOMIZATIONS)

    return permission.granularControls.can_reorder_questions
  }

  // Build a permission for a granular permission that contains a limit
  const buildBasePermission = (permissionType) => {
    switch (permissionType) {
      case CUSTOM_QUESTION_LIMIT:
        const parentPermission = permissions.find(
          (permission) => permission.type === CUSTOMIZATIONS
        )

        if (!parentPermission) return

        return {
          type: CUSTOM_QUESTION_LIMIT,
          enabled: parentPermission.enabled,
          limit: _isUsingChapterFeature()
            ? 10
            : parentPermission?.granularControls?.custom_question_limit || 0,
          consumed: surveyCustomQuestionCount, // Using state for this value
          unlimitedUsage: parentPermission.unlimitedUsage,
        }
      default:
        throw Error('Invalid permission type')
    }
  }

  const findPermission = (permissionType) => {
    let permission

    if (GRANULAR_LIMIT_PERMISSIONS.includes(permissionType)) {
      permission = buildBasePermission(permissionType)
    } else {
      permission = permissions.find(
        (permission) => permission.type === permissionType
      )
    }

    return _buildPermission(permission)
  }

  const _isUsingChapterFeature = () => {
    return !!selectedGroup
  }

  const onPaidPlan = () => {
    return price != null && price > 0
  }

  const getPlanTier = () => {
    if (!planTier) {
      return LITE_TIER
    }

    if (planTier === 0) {
      return LITE_TIER
    }

    if (planTier === 10) {
      return PRO_TIER
    }

    // Charter will always match the highest tier
    if (planTier === 20) {
      return ELITE_TIER
    }

    return LITE_TIER
  }

  const extendPermission = (permission) => {
    return {
      ...permission,
      consumedPercentage: () => {
        if (!permission.enabled) return 100

        if (permission.unlimitedUsage) {
          return 0
        }

        return Math.min((permission.consumed / permission.limit) * 100, 100)
      },
      // currentConsumption can be optionally passed in
      // consumed is generally set internally with api data or a survey state value
      isBelowLimit: (currentConsumption = null) => {
        const consumed = currentConsumption || permission.consumed

        if (!permission.enabled) return false

        switch (permission.type) {
          case OFFER_CODE_LIMIT:
            return canCreateSurveyOffer(consumed)
          default:
            return permission.unlimitedUsage || permission.limit > consumed
        }
      },
    }
  }

  const _buildPermission = (permission) => {
    // If the permission doesn't exist the authContext is being loaded.
    // Load a default permission state so we don't need try operators everywhere
    if (!permission) {
      return extendPermission(stateLoadingPermission)
    }

    // Override some values if they are using chapters
    let builtPermission = permission
    switch (permission.type) {
      case OFFER_CODE_LIMIT:
        builtPermission = {
          ...permission,
          unlimitedUsage: _isUsingChapterFeature() || permission.unlimitedUsage,
        }
        break
      case CUSTOMIZATIONS:
        builtPermission = {
          ...permission,
          enabled: _isUsingChapterFeature() || permission.enabled,
        }
        break
      default:
        builtPermission = permission
    }

    return extendPermission(builtPermission)
  }

  return {
    canToggleTalkadotBranding,
    canUseIntegrations,
    canAddCustomQuestion,
    canUseAIEventSummary,
    canDownloadLeads,
    canDownloadEventLeads,
    canUseEmailCampaigns,
    canCreateSurveyOffer,
    canAccessTeams,
    canReorderQuestions,
    findPermission,
    onPaidPlan,
    getPlanTier,
    canAccessAction,
  }
}
