import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion/dist/framer-motion'
import { styled, useTheme } from '@mui/system'
import { TableRow, TableCell, Typography, useMediaQuery } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'
import { ReactComponent as FilterIcon } from '../../icons/filterIcon_16x16.svg'
import { ReactComponent as ChatIcon } from '../../icons/chatBubbleSquareIcon_16x16.svg'
import { ReactComponent as MergeIcon } from '../../icons/mergeIcon_16x16.svg'
import { ReactComponent as RefreshIcon } from '../../icons/refreshIcon_16x16.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import { AdminContext } from '../admin/adminContext'
import { formatDate, eventReportCardShareLink } from '../common/helpers'
import { usePermissionHelper } from '../../utils/permission_helper'
import { exportEventLeads, exportEventRawData } from '../../utils/exportCsv'
import { syncEventLeadsWithSpeakerflow } from '../../services/speakerflow'

import { GENERIC as UPGRADE_MESSAGE } from '../../constants/messages/upgrade'

import TalksTableRowSpeaker from './TalksTableRowSpeaker'
import TalksTableRowGroup from './TalksTableRowGroup'
import TableCellStyled from '../common/TableCellStyled'
import CheckBoxStyled from '../common/CheckBoxStyled'
import { CheckboxTableCell } from '../common/TableComponents'

const StyledTableRow = styled(motion(TableRow), {
  shouldForwardProp: (prop) => prop !== 'animationState',
})(({ theme, animationState }) => ({
  cursor: 'pointer',
  '&:hover': {
    // Set the background color to the hover color if the row is not selected
    // need to do this here to override the animation props
    backgroundColor:
      animationState === 'unselected' &&
      theme.palette.table.hoverRow + ' !important',
  },
}))

const MergePrimeEventText = styled('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const TalksTableRow = ({
  event,
  selectEvent,
  deselectEvent,
  handleOpenEventForm,
  handleDeleteEvent,
  handleMergeEventsClick,
  handleMarkEventAsViewed,
  mergableEvents,
}) => {
  const [downloadingLeads, setDownloadingLeads] = useState(false)
  const [downloadingTestimonials, setDownloadingTestimonials] = useState(false)
  const [syncingLeads, setSyncingLeads] = useState(false)

  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { adminState } = useContext(AdminContext)
  const history = useHistory()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { canDownloadEventLeads, canUseIntegrations } = usePermissionHelper()

  const { user } = authData
  const { speakerflowEnabled, speakerflow_url, isAssociate } = user
  const isEventOwner =
    user?.id === event?.ownerId || selectedGroup?.id === event?.ownerId
  const eventMergable = event.isSelected && !event.isPrimeEvent
  const eventReportShortlink = selectedGroup
    ? selectedGroup.shortlink
    : user.shortlink

  const _handleSelectEvent = () => {
    return event.isSelected ? deselectEvent(event) : selectEvent(event)
  }

  const goToEventReport = () => {
    // If admin is impersonating, don't go to a new page
    if (adminState?.impersonation?.isActive) {
      return (
        !event.isSelected && history.push(`/event-report/${event.shareKey}`)
      )
    }

    isEventOwner && handleMarkEventAsViewed(event)

    // Don't go to the event report if the event is currently selected as a merge event
    return (
      !event.isSelected &&
      window.open(
        eventReportCardShareLink(eventReportShortlink, event.shareKey),
        '_blank',
        'noreferrer'
      )
    )
  }

  const handleDownloadEventLeads = async () => {
    try {
      setDownloadingLeads(true)
      const res = await exportEventLeads({ event })
      setDownloadingLeads(false)
      if (res.errors) {
        throw res.errors
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const handleDownloadEventTestimonials = async () => {
    try {
      setDownloadingTestimonials(true)
      const res = await exportEventRawData({ event })
      setDownloadingTestimonials(false)
      if (res.errors) {
        throw res.errors
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const handleSpeakerflowSync = async () => {
    try {
      setSyncingLeads(true)
      const res = await syncEventLeadsWithSpeakerflow({ event })
      setSyncingLeads(false)

      if (!res.errors) {
        setNotification(
          'Your leads from this event are syncing with speakerflow!',
          'success'
        )
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(
        'Oops - looks like there was something wrong with syncing your leads with Speakerflow. Please check your Speakerflow settings on the account settings page and try again. If the problem persists, contact us at hello@talkadot.com!',
        'error'
      )
    }
  }

  const menuItems = [
    {
      text: 'View Report',
      icon: <EyeIcon />,
      onClick: goToEventReport,
      visible: true,
    },
    {
      text: 'Edit Event',
      icon: <EditIcon />,
      onClick: () => handleOpenEventForm(event),
      visible: isEventOwner,
    },
    {
      text: 'Download Leads',
      icon: <FilterIcon />,
      onClick: handleDownloadEventLeads,
      visible: isEventOwner,
      disabled: !canDownloadEventLeads(),
      tooltipText: !canDownloadEventLeads() && UPGRADE_MESSAGE,
      loading: downloadingLeads,
    },
    {
      text: 'Download Testimonials',
      icon: <ChatIcon />,
      onClick: () => handleDownloadEventTestimonials(),
      visible: isEventOwner,
      disabled: !canDownloadEventLeads(),
      tooltipText: !canDownloadEventLeads() && UPGRADE_MESSAGE,
      loading: downloadingTestimonials,
    },
    {
      text: 'Sync Leads With Speakerflow',
      icon: <RefreshIcon />,
      onClick: handleSpeakerflowSync,
      visible:
        isEventOwner &&
        canUseIntegrations() &&
        speakerflowEnabled &&
        speakerflow_url,
      loading: syncingLeads,
    },
    {
      text: 'Merge Events',
      icon: <MergeIcon />,
      onClick: _handleSelectEvent,
      visible: isEventOwner,
    },
    {
      text: 'Delete Event',
      icon: <TrashIcon />,
      onClick: () => handleDeleteEvent(event),
      visible: isEventOwner && !isAssociate,
    },
  ]

  const renderRowContent = (onChildOpen, onChildClose) => {
    if (user?.isEventPlanner) {
      // TODO: Update in later round, or maybe not
      // return TalkTableRowEventPlanner
    } else if (selectedGroup) {
      return (
        <TalksTableRowGroup
          event={event}
          isEventOwner={isEventOwner}
          menuItems={menuItems}
          handleSelectEvent={_handleSelectEvent}
          goToEventReport={goToEventReport}
          handleOpenEventForm={handleOpenEventForm}
        />
      )
    }
    return (
      <TalksTableRowSpeaker
        event={event}
        isEventOwner={isEventOwner}
        menuItems={menuItems}
        handleSelectEvent={_handleSelectEvent}
        goToEventReport={goToEventReport}
        handleOpenEventForm={handleOpenEventForm}
        onChildOpen={onChildOpen}
        onChildClose={onChildClose}
      />
    )
  }

  const renderSelectedRowContent = () => {
    return (
      <>
        <CheckboxTableCell>
          <CheckBoxStyled
            checked={!!event.isSelected}
            onChange={_handleSelectEvent}
            inputProps={{ 'aria-labelledby': event.id }}
          />
        </CheckboxTableCell>
        <TableCell>{formatDate(event.date, event.utcOffset)}</TableCell>
        <TableCell>{event.talkTitle}</TableCell>
        <TableCellStyled displayBreakpoint="sm">
          {event.eventOrganization}
        </TableCellStyled>
        <TableCell colSpan={5}>{selectedRowText()}</TableCell>
      </>
    )
  }

  const selectedRowText = () => {
    if (event.isPrimeEvent) {
      return (
        <Typography
          variant="body2"
          align="center"
          onClick={handleMergeEventsClick}>
          {mergableEvents?.length > 0 ? (
            <MergePrimeEventText>
              {isMediumScreen
                ? 'Merge'
                : 'Merge selected events into this event'}
            </MergePrimeEventText>
          ) : (
            <strong>Select other events to merge into this event</strong>
          )}
        </Typography>
      )
    }
    return (
      <Typography variant="body1" align="center">
        Merge into selected event
      </Typography>
    )
  }

  const variants = {
    primarySelected: {
      backgroundColor: theme.palette.table.selectedRowPrimary,
      color: theme.palette.base.white,
      boxShadow: theme.shape.boxShadow.sm,
    },
    secondarySelected: {
      backgroundColor: theme.palette.table.selectedRowSecondary,
      boxShadow: theme.shape.boxShadow.xs,
    },
    unselected: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
    },
    hover: {
      backgroundColor: theme.palette.table.hoverRow,
    },
  }

  const animationState = () => {
    if (event.isPrimeEvent) {
      return 'primarySelected'
    }
    if (eventMergable) {
      return 'secondarySelected'
    }
    return 'unselected'
  }

  return (
    <StyledTableRow
      key={event.id}
      animate={animationState()}
      animationState={animationState()}
      variants={variants}>
      {event.isSelected ? renderSelectedRowContent() : renderRowContent()}
    </StyledTableRow>
  )
}

export default TalksTableRow
