import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '39px',
}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const Caption = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main
}))

const CodeBuilderSettingsTitle = ({ title, subTitle, caption }) => {
  return (
    <TitleContainer>
      <Title variant="body2">{title}</Title>
      {subTitle && <Typography variant="body1">{subTitle}</Typography>}
      {caption && <Caption variant="paragraph00">{caption}</Caption>}
    </TitleContainer>
  )
}

export default CodeBuilderSettingsTitle
