import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'
import CodeBuilderDeleteButton from './CodeBuilderDeleteButton'
import CodeBuilderQuestionTypePicker from './CodeBuilderQuestionTypePicker'
import CodeBuilderLaunchPreviewButton from './CodeBuilderLaunchPreviewButton'
import CodeBuilderAddQuestionButton from './CodeBuilderAddQuestionButton'

import { usePermissionHelper } from '../../../utils/permission_helper'

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(0.75),
  minHeight: theme.spacing(5.5),
  background: theme.palette.surface.subtle,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
}))

const QuestionType = styled(Typography)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.hint,
  fontWeight: theme.fontWeights.semiBold,
  marginRight: theme.spacing(1),
  span: {
    color: theme.palette.text.link,
  },
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
}))

const QuestionHeaderActionButtonsContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(1.5),
  justifyContent: 'flex-end',
}))

export const QuestionHeaderActionButton = styled(Button)(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  whiteSpace: 'nowrap',
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '32px',
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': { margin: 0 },
  },
}))

const CodeBuilderQuestionHeader = ({
  code,
  question,
  canInsertQuestion,
  updateQuestion,
  changeQuestionType,
  handleAddQuestion,
  handleDeleteQuestion,
  questionLoading,
  availableQuestionTypes,
}) => {
  const { canAccessAction } = usePermissionHelper()

  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  const showQuestionType =
    question.questionType !== 'code' &&
    question.questionType !== 'downloadSlide'

  const showPreviewButton = !question.hidePreview && code

  const renderHeaderContent = () => {
    return (
      <>
        <QuestionType variant="body1" component="div">
          {showQuestionType && 'Question Type: '}
          <span>{question.config?.questionTypeDescription}</span>
          {/* don't render the picker if there are no available question types */}
          {question?.config?.canChangeQuestionType &&
            canAccessAction(question.config.changeQuestionTypeTier) &&
            availableQuestionTypes?.length > 0 && (
              <CodeBuilderQuestionTypePicker
                availableQuestionTypes={availableQuestionTypes}
                changeQuestionType={changeQuestionType}
                question={question}
              />
            )}
        </QuestionType>
        <>
          <QuestionHeaderActionButtonsContainer>
            {showPreviewButton && (
              <CodeBuilderLaunchPreviewButton code={code} />
            )}
            {canInsertQuestion && (
              <CodeBuilderAddQuestionButton
                questionLoading={questionLoading}
                handleAddQuestion={handleAddQuestion}
                availableQuestionTypes={availableQuestionTypes}
              />
            )}
          </QuestionHeaderActionButtonsContainer>
          {question.config?.canToggleEnable && (
            <CodeBuilderToggleQuestionButton
              handleToggleQuestion={() => updateQuestionEnabled(question.id)}
              enabled={question.enabled}
              question={question}
              canToggleVisibility={canAccessAction(
                question.config.toggleEnabledTier
              )}
              toggleEnabledTier={question.config.toggleEnabledTier}
            />
          )}
          {question.config?.canDelete && (
            <CodeBuilderDeleteButton
              handleDelete={() => handleDeleteQuestion(question.id)}
            />
          )}
        </>
      </>
    )
  }

  return <HeaderContainer>{renderHeaderContent()}</HeaderContainer>
}

export default CodeBuilderQuestionHeader
