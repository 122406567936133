import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import { capitalize } from '../../utils/string'

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend)

// Register custom plugin locally
const customAverageLabelPlugin = {
  id: 'customAverageLabel',
  beforeDraw(chart) {
    const { width, height, ctx } = chart
    const averagePercentage =
      chart.options.plugins.customAverageLabel?.averagePercentage || '0'

    ctx.save()

    const text = `${averagePercentage}`
    ctx.font = 'bold 36px Figtree, sans-serif'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    const textX = width / 2
    const textY = height * 0.75

    ctx.fillText(text, textX, textY)
    ctx.restore()
  },
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  flex: 1,
}))

const DonutContainer = styled('div')(({ theme }) => ({
  width: '320px',
  height: '160px', // Limit the container height to half the canvas height
  overflow: 'hidden', // Hide any overflow from the canvas
  display: 'flex',
  justifyContent: 'center', // Center the donut chart horizontally
  [theme.breakpoints.down('sm')]: {
    width: '260px',
    height: '130px',
  },
}))

const LegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
  maxWidth: '320px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '260px',
  },
}))

const NpsRatingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(1),
}))

const NpsRatingIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(1),
}))

const NpsRatingIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ratingType',
})(({ theme, ratingType }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor:
    ratingType === 'detractors'
      ? theme.palette.emojis.red
      : ratingType === 'passives'
      ? theme.palette.emojis.yellow
      : theme.palette.emojis.green,
}))

const CustomQuestionNps = ({ question }) => {
  const theme = useTheme()

  const totalAnswers = question.answers.length

  const npsRatings = question.answers.reduce(
    (acc, answer) => {
      if (answer.range_value >= 0 && answer.range_value <= 6) {
        acc.detractors.count++
      } else if (answer.range_value >= 7 && answer.range_value <= 8) {
        acc.passives.count++
      } else if (answer.range_value >= 9 && answer.range_value <= 10) {
        acc.promoters.count++
      }

      // Update percentages dynamically
      acc.detractors.percentage = Math.round(
        (acc.detractors.count / totalAnswers) * 100
      )
      acc.passives.percentage = Math.round(
        (acc.passives.count / totalAnswers) * 100
      )
      acc.promoters.percentage = Math.round(
        (acc.promoters.count / totalAnswers) * 100
      )

      return acc
    },
    {
      promoters: { count: 0, percentage: 0.0 },
      passives: { count: 0, percentage: 0.0 },
      detractors: { count: 0, percentage: 0.0 },
    }
  )

  const npsScore =
    npsRatings.promoters.percentage - npsRatings.detractors.percentage

  const chartData = {
    labels: Object.keys(npsRatings).map((rating) => capitalize(rating)),
    datasets: [
      {
        data: Object.values(npsRatings).map((rating) => rating.count),
        backgroundColor: [
          theme.palette.emojis.green,
          theme.palette.emojis.yellow,
          theme.palette.emojis.red,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: -90, // Start from the top
    circumference: 180, // Draw a half-donut
    cutout: '70%', // Make it look like a gauge by increasing the inner radius
    plugins: {
      legend: {
        display: false, // Hide the legend if you want custom labels below the chart
      },
      tooltip: {
        enabled: true,
      },
      customAverageLabel: {
        averagePercentage: npsScore, // Pass the percentage dynamically
      },
    },
  }

  return (
    <Container>
      <DonutContainer>
        <Doughnut
          data={chartData}
          options={options}
          plugins={[customAverageLabelPlugin]}
        />
      </DonutContainer>
      <LegendContainer>
        {Object.entries(npsRatings).map(([ratingType, rating]) => (
          <NpsRatingContainer key={ratingType}>
            <NpsRatingIconContainer>
              <NpsRatingIcon ratingType={ratingType} />
              <Typography variant="body1">{capitalize(ratingType)}</Typography>
            </NpsRatingIconContainer>
            <Typography variant="body1">{rating.percentage}%</Typography>
          </NpsRatingContainer>
        ))}
      </LegendContainer>
    </Container>
  )
}

export default CustomQuestionNps
