import { styled } from '@mui/system'
import React, { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { ReactComponent as ThumbUpIcon } from '../../icons/thumbs_up_16x16.svg'
import { ReactComponent as ThumbDownIcon } from '../../icons/thumbs_down_16x16.svg'

const LikeDislikeButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => ({
  '& svg path': {
    stroke: variant === 'selected' ? null : theme.palette.neutral.light,
    strokeWidth: variant === 'selected' ? null : 1,
  },
}))

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
}))

const LabelText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.neutral.main,
  height: '100%',
}))

const LikeDislike = ({ ratingState, changeState, labelText }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState(ratingState)

  const label = labelText || 'Is this summary valuable to you?'

  const handleRatingChange = async (action) => {
    const prevState = state
    let newState

    // Determine the new state based on the action and current state
    if (action === 'thumbs_up') {
      newState = prevState === 'thumbs_up' ? 'neutral' : 'thumbs_up'
    } else if (action === 'thumbs_down') {
      newState = prevState === 'thumbs_down' ? 'neutral' : 'thumbs_down'
    }

    setIsLoading(true) // Set loading state before performing async operation
    await changeState(newState, prevState) // Perform the async operation
    setIsLoading(false) // Reset loading state after the operation

    setState(newState) // Update the state after async operation is complete
  }

  return (
    <Container>
      <LabelText variant="caption">{label}</LabelText>
      <IconContainer>
        <LikeDislikeButton
          variant={state === 'thumbs_up' ? 'selected' : 'default'}
          onClick={() => handleRatingChange('thumbs_up')}
          disabled={isLoading}
        >
          <ThumbUpIcon />
        </LikeDislikeButton>
        <LikeDislikeButton
          variant={state === 'thumbs_down' ? 'selected' : 'default'}
          onClick={() => handleRatingChange('thumbs_down')}
          disabled={isLoading}
        >
          <ThumbDownIcon />
        </LikeDislikeButton>
      </IconContainer>
    </Container>
  )
}

export default LikeDislike
