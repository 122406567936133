import EmailCampaignSettings from './campaigns/EmailCampaignSettings'
import PageContainer from '../common/PageContainer'

const LeadsFollowupParent = () => {
  return (
    <PageContainer>
      <EmailCampaignSettings />
    </PageContainer>
  )
}

export default LeadsFollowupParent
