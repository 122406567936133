import { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { styled } from '@mui/system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as CaretButton } from './icons/caretLeftButton.svg'

import Routes from './routes/index'
import FullPageLoader from './features/common/FullPageLoader'
import NotificationParent from './features/common/NotificationParent'
import UpgradeParent from './features/payments/UpgradeParent'

import { DrawerHeader } from './styles/layoutNavConstants'
import UpperNav from './features/nav/UpperNav'
import LeftNav from './features/nav/LeftNav'

import { AuthenticationContext } from './features/authentication/authenticationContext'
import { GroupContext } from './features/groups/groupContext'
import { OnboardingContext } from './features/onboarding/onboardingContext'

import Api from './services/api'
import history from './routes/history'
import * as Cookies from './services/cookies'
import { trackUser } from './services/metrics'
import {
  drawerWidth,
  drawerWidthCollapsed,
  drawerWidthMobile,
  upperNavHeight,
} from './styles/layoutNavConstants'

import useFrontChat from "./utils/useFrontChat";

import './App.scss'

const AppContainer = styled(Box)({
  display: 'flex',
})

const MainContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'leftNavOpen' && prop !== 'showLeftNav',
})(({ theme, leftNavOpen, showLeftNav }) => ({
  position: 'relative',
  flexGrow: 1,
  minHeight: '100vh',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface.subtle,
  width: showLeftNav
    ? `calc(100% - ${leftNavOpen ? drawerWidth : drawerWidthCollapsed}px)`
    : '100%',
  // add width transition to make the main content resize smoothly and match the drawer
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: showLeftNav
      ? `calc(100% - ${leftNavOpen ? drawerWidth : drawerWidthMobile}px)`
      : '100%',
    // add left margin to the main content to account for the drawer width due to the drawer being fixed on mobile
    marginLeft: showLeftNav ? drawerWidthMobile : 0,
  },
}))

const DrawerToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: `1.5px solid ${theme.palette.border.main}`,
  backgroundColor: theme.palette.base.white,
  boxShadow: theme.shape.boxShadow.xs,
  width: '38px',
  height: '38px',
  padding: 0,
  position: 'fixed',
  top: upperNavHeight,
  left: open ? drawerWidth : drawerWidthCollapsed,
  transform: 'translate(-50%, -50%)',
  zIndex: 1302,
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&:hover': {
    backgroundColor: theme.palette.surface.subtle,
  },
  '& svg': {
    transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

function App() {
  let location = useLocation()
  const { setUserData, authData, setNotification, toggleUpgradeModal } =
    useContext(AuthenticationContext)
  const { setGroups } = useContext(GroupContext)
  const { setCurrentStep, findStartingStep } = useContext(OnboardingContext)
  const { notification, showUpgradeModal, upgradeHelperText, user } = authData

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState(false)
  const [leftNavOpen, setLeftNavOpen] = useState(!isMobileOnly)
  const [autoCloseDrawer, setAutoCloseDrawer] = useState(false)

  useFrontChat(user)

  const toggleDrawer = () => {
    setLeftNavOpen(!leftNavOpen)
  }

  const closeDrawer = () => {
    setLeftNavOpen(false)
  }

  const handleAutoOpenDrawer = () => {
    if (!leftNavOpen) {
      setLeftNavOpen(true)
      setAutoCloseDrawer(true)
    }
  }

  const handleAutoCloseDrawer = () => {
    if (leftNavOpen && autoCloseDrawer) {
      setLeftNavOpen(false)
      setAutoCloseDrawer(false)
    }
  }

  useEffect(() => {
    const loadAuthenticatedUsersData = async () => {
      // Clear out impersonation tokens on browser refreshes or fresh app reloads
      Cookies.deleteImpersonationToken()

      const isAuthenticated = Cookies.getAuthToken()

      // Don't try and run this if the user isn't currently signed in
      if (!isAuthenticated) {
        return
      }

      try {
        setLoading(true)

        const res = await Api.loadAuthenticatedUserData()

        if (!res.errors) {
          const userData = res.userData
          const {
            id,
            onboarding_complete,
            lastOnboardingStep,
            onboarding_info,
            isEventPlanner,
          } = userData

          setUserData(userData)
          setGroups(res.groupData)
          setCurrentStep(findStartingStep(lastOnboardingStep))
          setLoading(false)
          trackUser(id)
          if (!onboarding_complete) {
            return history.push('/welcome')
          }
          if (
            !isEventPlanner &&
            onboarding_info?.survey_offers &&
            onboarding_info?.survey_offers?.completed === false
          ) {
            return history.push('/survey-onboarding')
          }
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err, 'error')
        setLoading(false)
      }
    }

    loadAuthenticatedUsersData()
  }, [])

  useEffect(() => {
    if (isMediumScreen && leftNavOpen) {
      setLeftNavOpen(false)
    }

    if (isLargeScreen && !leftNavOpen) {
      setLeftNavOpen(true)
    }
  }, [isMediumScreen, isLargeScreen])

  const NO_NAVBAR_PAGES = [
    '/survey',
    '/welcome',
    '/register',
    '/login',
    '/request-password-reset',
    '/password-reset',
    '/referrer',
    '/oauth/authorize',
    '/unsubscribe',
    '/thank-you-gift',
    '/api/docs',
  ]

  const shouldShowNavbar = !NO_NAVBAR_PAGES.includes(location.pathname)

  const renderMainContentHeader = () => {
    if (shouldShowNavbar) {
      return (
        <>
          <DrawerHeader />
          {user?.id && !isMobileScreen && (
            <Tooltip
              title="Toggle Navigation"
              placement="right"
              disableInteractive>
              <DrawerToggleButton
                onClick={() => toggleDrawer()}
                open={leftNavOpen}>
                <CaretButton />
              </DrawerToggleButton>
            </Tooltip>
          )}
        </>
      )
    }
  }

  const renderAppLayout = () => {
    if (loading) {
      return <FullPageLoader content="Loading your account data!" />
    }

    return (
      <div className="App">
        <AppContainer>
          {shouldShowNavbar && (
            <>
              <UpperNav
                user={user}
                open={leftNavOpen}
                toggleDrawer={toggleDrawer}
                closeDrawer={closeDrawer}
                handleAutoOpenDrawer={handleAutoOpenDrawer}
              />
              {user?.id && (
                <LeftNav
                  open={leftNavOpen}
                  closeDrawer={closeDrawer}
                  handleAutoOpenDrawer={handleAutoOpenDrawer}
                  handleAutoCloseDrawer={handleAutoCloseDrawer}
                />
              )}
            </>
          )}
          <MainContentContainer
            component="main"
            leftNavOpen={leftNavOpen}
            // add media query to shouldShowNavbar to hide the left nav on mobile
            showLeftNav={user?.id && shouldShowNavbar && !isMobileScreen}>
            {renderMainContentHeader()}
            <Routes leftNavOpen={leftNavOpen} />
            {authData?.fullPageLoader?.isActive && (
              <FullPageLoader
                content={authData.fullPageLoader.content}
                loaderType={authData.fullPageLoader.loaderType}
              />
            )}
          </MainContentContainer>
        </AppContainer>
        {notification && notification.showNotification && (
          <NotificationParent />
        )}
        <Dialog
          open={showUpgradeModal}
          fullWidth
          maxWidth="lg"
          onClose={() => toggleUpgradeModal(false)}>
          <CloseIcon
            className="modal-close-icon"
            id="upgrade-page"
            onClick={() => toggleUpgradeModal(false)}
          />
          {upgradeHelperText && (
            <DialogTitle className="upgrade-helper-text">
              {upgradeHelperText}
            </DialogTitle>
          )}
          <DialogContent>
            <UpgradeParent />
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  return renderAppLayout()
}

export default App
