import { useState, useContext } from 'react'
import { isURL } from 'validator'

import CodeBuilderSettingsTitle from './CodeBuilderSettingsTitle'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputIcon from './CodeBuilderInputIcon'

import { ReactComponent as LinkIcon } from '../../../icons/linkIcon_16x16.svg'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { usePermissionHelper } from '../../../utils/permission_helper'

import UpgradeModalLauncher from '../../common/UpgradeModalLauncher'

const CodeBuilderSettingsBookingLink = ({ setActiveTab }) => {
  const [bookingLinkError, setBookingLinkError] = useState(false)
  const [bookingLinkLoading, setBookingLinkLoading] = useState(false)

  const { authData, setProfileAttribute, submitBookingLink } = useContext(
    AuthenticationContext
  )
  const { user } = authData

  const isOnPaidPlan =
    usePermissionHelper().onPaidPlan()

  const handleBookingLinkBlur = async () => {
    setBookingLinkLoading(true)
    await submitBookingLink()
    setBookingLinkLoading(false)
  }

  const handleOnChange = (value) => {
    setProfileAttribute('booking_link', value)
    setActiveTab(1)
  }

  const textInputValidation = (value) => {
    if (value && !isURL(value, { require_host: false })) {
      return {
        message: 'Link must be a valid URL',
        allowContinue: true,
        allowSubmit: false,
      }
    }

    if (value?.length > 500) {
      return {
        message: 'Must be 500 characters or less',
        allowContinue: false,
      }
    }
  }

  return (
    <>
      <CodeBuilderSettingsTitle
        title="Allow qualified leads to schedule a meeting with you?"
        caption="Changing this setting will change it for all of your codes."
      />
      {!isOnPaidPlan && (
        <div>
          <UpgradeModalLauncher
            content="Upgrade To Add"
            variant="outlined"
            upgradeHelperText="Upgrade To Add Your Calendar Link To Your Talkadot Flow!"
          />
        </div>
      )}
      <CodeBuilderInputText
        value={user?.booking_link || ''}
        handleOnChange={handleOnChange}
        handleOnBlur={handleBookingLinkBlur}
        placeholder="Paste your scheduling link - e.g. Calendly"
        canEdit={true}
        error={bookingLinkError}
        setError={setBookingLinkError}
        disabled={bookingLinkLoading || !isOnPaidPlan}
        inputValidation={textInputValidation}
        hint="Only qualified leads who give you their full name and full contact information can schedule with you."
        marginTop="1.5rem"
        inputDescription={
          <CodeBuilderInputIcon icon={<LinkIcon />} fillType="stroke" />
        }
      />
    </>
  )
}

export default CodeBuilderSettingsBookingLink
