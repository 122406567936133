import { useContext } from 'react'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { Notification } from '../../common/Notification'
import { styled } from '@mui/system'

import {
  usePermissionHelper,
  LITE_TIER,
} from '../../../utils/permission_helper'

const UpgradeLink = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const CodeBuilderUpgradeNotification = ({ currentQuestion }) => {
  const {
    toggleUpgradeModal,
    authData: {
      user: {
        membership: {
          plan: { planTier },
        },
      },
    },
  } = useContext(AuthenticationContext)
  const { getPlanTier } = usePermissionHelper()
  const planType = getPlanTier()
  const minTierRequirement = (() => {
    // Determine the minimum tier requirement for gated features in the current question
    // If the question has both Pro and Elite gating a Lite user should see both Pro and Elite when they click on this modal
    const {
      config: {
        canEditContent,
        editContentTier,
        canToggleEnable,
        toggleEnabledTier,
        canEditViaDropdown,
        editViaDropdownTier,
      },
    } = currentQuestion

    let tierRequirements = []

    const collectTierRequirements = (canEdit, tierRequirement) => {
      if (canEdit) {
        tierRequirements.push(tierRequirement)
      }
    }

    if (currentQuestion.config.isTalkadotQuestion) {
      for (const answer of currentQuestion.possibleAnswers) {
        const {
          config: {
            canEditContent: answerCanEditContent,
            editContentTier: answerEditContentTier,
            canToggleEnable: answerCanToggleEnable,
            toggleEnabledTier: answerToggleEnabledTier,
            canToggleRequired: answerCanToggleRequired,
            toggleRequiredTier: answerToggleRequiredTier,
          },
        } = answer

        collectTierRequirements(answerCanToggleEnable, answerToggleEnabledTier)
        collectTierRequirements(answerCanEditContent, answerEditContentTier)
        collectTierRequirements(
          answerCanToggleRequired,
          answerToggleRequiredTier
        )
      }
    }

    collectTierRequirements(canToggleEnable, toggleEnabledTier)
    collectTierRequirements(canEditContent, editContentTier)
    collectTierRequirements(canEditViaDropdown, editViaDropdownTier)

    tierRequirements = [...new Set(tierRequirements)]

    const gatedTierValues = tierRequirements.filter((tier) => tier > planTier)

    if (gatedTierValues.length === 0) {
      // question does not have any features they cannot use
      return 0
    } else {
      // return the tier of the lowest feature they cannot use
      return Math.min(...gatedTierValues)
    }
  })()

  const renderUpgradeLink = () => (
    <UpgradeLink
      onClick={() =>
        toggleUpgradeModal(true, 'Upgrade for more customization options!', {
          tierRequirement: minTierRequirement,
        })
      }>
      upgrade your plan.
    </UpgradeLink>
  )

  // If the entire realm of customizations is not available for the speaker, show a different
  // message than if some of them are available and some are not
  const upgradeNotificationContent = () => {
    if (planType === LITE_TIER) {
      return (
        <>To make changes or to skip this question, {renderUpgradeLink()}</>
      )
    } else {
      return <>To make changes to this question, {renderUpgradeLink()}</>
    }
  }

  const showUpgradeNotice = () => {
    if (planTier >= minTierRequirement) {
      return false
    }

    return upgradeNotificationContent()
  }

  return (
    showUpgradeNotice() && (
      <Notification
        variant="blueWarning"
        hideIcon={false}
        hideClose={true}
        maxWidth={true}>
        {upgradeNotificationContent()}
      </Notification>
    )
  )
}

export default CodeBuilderUpgradeNotification
