import { useContext } from 'react'
import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'

import { ReactComponent as DocumentReportIcon } from '../../icons/documentReportIcon_20x20.svg'

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

const ContainerInner = styled('div')(({ theme }) => ({
  gridColumn: 'span 2',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}))

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  borderBottom: `3px solid ${theme.palette.secondary.main}`,
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  margin: 0,
  color: theme.palette.neutral.veryDark,
}))

const StyledBody = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
}))

const UpgradeButton = styled(Button)(({ theme }) => ({
  width: 'max-content',
  fontSize: '0.875rem',
  fontWeight: '400',
  padding: `0 ${theme.spacing(1)}`,
}))

const Footer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const FooterTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const CustomQuestionUpsell = () => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  return (
    <Container>
      <ContainerInner>
        <HeaderContainer>
          <StyledHeader variant="h5">Custom question data</StyledHeader>
        </HeaderContainer>
        <StyledBody variant="body1">
          This data is only viewable on a paid plan.
        </StyledBody>
        <Footer>
          <DocumentReportIcon />
          <FooterTextContainer>
            <UpgradeButton
              variant="text"
              color="secondary"
              onClick={() =>
                toggleUpgradeModal(
                  true,
                  'Upgrade to unlock Hidden Custom Question Data!'
                )
              }>
              <span>Upgrade to view custom question data.</span>
            </UpgradeButton>
          </FooterTextContainer>
        </Footer>
      </ContainerInner>
    </Container>
  )
}

export default CustomQuestionUpsell
