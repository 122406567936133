import StatsSummaryHeader from '../../common/StatsSummaryHeader'

import { ReactComponent as LeadsIcon } from '../../../icons/leadsIcon_16x16.svg'
import { ReactComponent as FireIcon } from '../../../icons/fireIcon_16x16.svg'
import { ReactComponent as WindIcon } from '../../../icons/windIcon_16x16.svg'
import { ReactComponent as EmailIcon } from '../../../icons/mailIcon_16x16.svg'

import { processWithFallback } from '../../common/helpers'

const EmailCampaignStatSummary = ({ emailCampaigns, bookingCampaign, referralCampaign, optInCampaign, unlimitedUsage, isBelowLimit, canUseEmailCampaigns }) => {
  const allSentEmails = emailCampaigns.reduce((sum, item) => sum + item.emailsSent, 0)

  const statsSummaryItems = [
    {
      label: 'Total followup emails sent',
      value: processWithFallback(allSentEmails),
      icon: <LeadsIcon />,
      svgType: 'fill',
      useErrorFontColor: canUseEmailCampaigns() && !unlimitedUsage && !isBelowLimit(),
    },
    {
      label: 'Total Booking Followup Emails Sent',
      value: processWithFallback(bookingCampaign?.emailsSent),
      icon: <FireIcon />,
      svgType: 'fill',
    },
    {
      label: 'Total Referral Followup Emails Sent',
      value: processWithFallback(referralCampaign?.emailsSent),
      icon: <WindIcon />,
      svgType: 'stroke',
    },
    {
      label: 'Total Opt In Bonus Emails Sent',
      value: processWithFallback(optInCampaign?.emailsSent),
      icon: <EmailIcon />,
      svgType: 'stroke',
    },
  ]

  return(
    <StatsSummaryHeader statItems={statsSummaryItems} />
  )

}

export default EmailCampaignStatSummary