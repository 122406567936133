import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { Typography, Tooltip, IconButton } from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { usePermissionHelper } from '../../../utils/permission_helper'

import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'
import CheckBoxStyled from '../../common/CheckBoxStyled'

import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '42px',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  // marginBottom: theme.spacing(1),
  width: '90%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

const ContentContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}))

const ToggleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const SettingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flex: '0 0 35%',
  marginBottom: '10px',
  [theme.breakpoints.down('lg')]: {
    flex: '0 0 10%',
  },
}))

const Description = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
  color: theme.palette.neutral.veryDark,
}))

const CodeBuilderPossibleAnswer = ({
  question,
  possibleAnswer,
  questionLoading,
  updatePossibleAnswer,
  handleDeletePossibleAnswer,
  setCurrentQuestion,
  setActiveTab,
}) => {
  const [newContent, setNewContent] = useState(possibleAnswer.content)
  const [error, setError] = useState(null)

  // const { findPermission } = usePermissionHelper()
  // const customizationPermission = findPermission(CUSTOMIZATIONS)
  // const { granularControls } = customizationPermission
  const { canAccessAction } = usePermissionHelper()

  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const {
    config: {
      canEditContent,
      canToggleRequired,
      canToggleEnable,
      builderDescription,
      canDelete,
      editContentTier,
      toggleEnabledTier,
      toggleRequiredTier,
    },
  } = possibleAnswer

  const allowDelete =
    canDelete &&
    question.possibleAnswers?.length > 2 &&
    !possibleAnswer.anyLinkedAnswers

  const textInputValidation = (value) => {
    if (question?.config?.isTalkadotQuestion && value?.length === 0) {
      return {
        message: 'Make sure to add some content to this option.',
        allowContinue: true,
        warningVariant: 'blueWarning',
        allowSubmit: false,
      }
    }

    if (
      !question.config.isTalkadotQuestion &&
      possibleAnswer.anyLinkedAnswers
    ) {
      return {
        message:
          'There are already answers for this option, so it can no longer be changed.',
        allowContinue: false,
        warningVariant: 'blueWarning',
        allowSubmit: false,
      }
    }

    // Check for duplicate content
    if (value?.length > 0) {
      const duplicateAnswer = question.possibleAnswers.find(
        (answer) => answer.id !== possibleAnswer.id && answer.content === value
      )

      if (duplicateAnswer) {
        return {
          message: 'There is already an answer with this value.',
          allowContinue: true,
          allowSubmit: false,
        }
      }
    }

    if (value?.length > 50) {
      return {
        message: 'Value must be 50 characters or less',
        allowContinue: false,
        allowSubmit: true,
      }
    }
  }

  const handleTogglePossibleAnswerSetting = async (updateParams) => {
    if (!canAccessAction(toggleEnabledTier)) {
      return toggleUpgradeModal(
        true,
        'Upgrade your plan to change question settings and more!',
        { tierRequirement: toggleEnabledTier }
      )
    }

    return await updatePossibleAnswer(possibleAnswer.id, updateParams)
  }

  // Note:
  // When loading multiple choice possible answers, since creating them is a dynamic
  // process we don't have a builder description with the appropriate "option number"
  // to render.  this is a bit a of a hack to make sure we show "option 1, option 2, etc"
  // on the UX
  const makeOptionDescription = () => {
    if (
      possibleAnswer.answerType === 'MULTIPLE_CHOICE' &&
      !question.config.isTalkadotQuestion &&
      possibleAnswer.displayOrder !== undefined &&
      possibleAnswer.displayOrder !== null
    ) {
      return `${builderDescription} ${possibleAnswer.displayOrder + 1}`
    }

    return builderDescription
  }

  const handleUpdatePossibleAnswerContent = (newValue) => {
    setCurrentQuestion({
      ...question,
      possibleAnswers: question.possibleAnswers.map((pa) =>
        pa.id === possibleAnswer.id ? { ...pa, content: newValue } : pa
      ),
    })
    setNewContent(newValue)
  }

  const renderContent = () => {
    if (canEditContent) {
      return (
        <CodeBuilderInputText
          key={possibleAnswer.id}
          inputDescription={
            <Typography variant="body1">{makeOptionDescription()}</Typography>
          }
          placeholder="Enter an answer"
          value={newContent}
          editConfig={possibleAnswer.config.canEditContent}
          canEdit={canAccessAction(editContentTier)}
          error={error}
          setError={setError}
          handleOnChange={(newValue) => {
            setActiveTab(1)
            handleUpdatePossibleAnswerContent(newValue)
          }}
          handleOnBlur={(newValue) =>
            updatePossibleAnswer(possibleAnswer.id, { content: newValue })
          }
          inputValidation={textInputValidation}
          disabled={questionLoading}
          autoFocus={false}
        />
      )
    } else {
      return (
        <Description variant="body1" component="div">
          {builderDescription || possibleAnswer.content}
        </Description>
      )
    }
  }

  const renderSettings = () => {
    return (
      <SettingsContainer>
        {canToggleEnable && (
          <ToggleWrapper>
            <CodeBuilderToggleQuestionButton
              handleToggleQuestion={() => {
                setActiveTab(1)
                handleTogglePossibleAnswerSetting({
                  enabled: !possibleAnswer.enabled,
                })
              }}
              canToggleVisibility={canAccessAction(toggleEnabledTier)}
              toggleEnabledTier={toggleEnabledTier}
              enabled={possibleAnswer.enabled}
              enabledTooltip="Answer is enabled. Toggle to disable answer."
              disabledTooltip="Answer is disabled, it will not be shown for this question. Toggle to enable answer."
            />
          </ToggleWrapper>
        )}
        {canToggleRequired && (
          <Tooltip
            disableInteractive
            title={
              !canAccessAction(toggleRequiredTier) &&
              'Upgrade your plan to toggle this setting'
            }>
            <div>
              <CheckBoxStyled
                sx={{ padding: 0 }}
                label="make required"
                checked={possibleAnswer.inputRequired}
                disabled={questionLoading || !possibleAnswer.enabled}
                onChange={() => {
                  setActiveTab(1)
                  handleTogglePossibleAnswerSetting({
                    input_required: !possibleAnswer.inputRequired,
                  })
                }}
              />
            </div>
          </Tooltip>
        )}
        {allowDelete && (
          <Tooltip title="Remove this answer option" disableInteractive>
            <IconButton
              onClick={() => handleDeletePossibleAnswer(possibleAnswer.id)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </SettingsContainer>
    )
  }

  return (
    <Container>
      <ContentContainer>{renderContent()}</ContentContainer>
      {renderSettings()}
    </Container>
  )
}

export default CodeBuilderPossibleAnswer
