import { useState, useContext } from 'react'
import { styled } from '@mui/system'

import { Menu, Tooltip } from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { renderStyledMenuItem } from './CodeBuilderQuestionTypePicker'
import { QuestionHeaderActionButton } from './CodeBuilderQuestionHeader'

import { ReactComponent as PlusIcon } from '../../../icons/plusIcon_16x16.svg'

import {
  usePermissionHelper,
  CUSTOM_QUESTION_LIMIT,
} from '../../../utils/permission_helper'

import { MenuItem } from '@mui/material'

const NewQuestionNoticeContainer = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.lightest,
  fontSize: theme.typography.paragraph00,
  textWrap: 'wrap',
  maxWidth: '232px',
  '&:hover': {
    backgroundColor: theme.palette.neutral.lightest,
  },
  marginBottom: '8px',
  marginTop: '-8px',
  paddingTop: '12px',
  paddingBottom: '12px',
}))

const CodeBuilderAddQuestionButton = ({
  questionLoading,
  handleAddQuestion,
  availableQuestionTypes,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const {
    toggleUpgradeModal,
    authData: {
      user: {
        membership: { shouldShowUpgrades },
      },
    },
  } = useContext(AuthenticationContext)

  const { findPermission } = usePermissionHelper()
  const customQuestionPermission = findPermission(CUSTOM_QUESTION_LIMIT)

  const _handleAddQuestion = async (questionTypeId) => {
    if (shouldShowUpgrades && !customQuestionPermission.isBelowLimit()) {
      return toggleUpgradeModal(
        true,
        'Upgrade your plan to add more questions.',
        { permissionType: 'custom_question_limit' }
      )
    }

    await handleAddQuestion(questionTypeId)
    handleClose()
  }

  return (
    <>
      <Tooltip
        title="Add question after this question"
        disableInteractive
        arrow>
        <QuestionHeaderActionButton
          variant="outlined"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          startIcon={<PlusIcon />}
          aria-expanded={open ? 'true' : undefined}
          color="tertiary"
          disabled={questionLoading}
          onClick={handleClick}>
          <span className="button-text">Add a question</span>
        </QuestionHeaderActionButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <NewQuestionNoticeContainer key={0}>
          Your new question is added after the current question
        </NewQuestionNoticeContainer>
        {availableQuestionTypes?.map((questionType, i) => {
          return (
            <div onClick={() => _handleAddQuestion(questionType.id)} key={i}>
              {renderStyledMenuItem(questionType, {})}
            </div>
          )
        })}
      </Menu>
    </>
  )
}

export default CodeBuilderAddQuestionButton
