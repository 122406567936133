import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// Error Logging
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import history from './routes/history'
import reportWebVitals from './reportWebVitals'
import AuthenticationContextProvider from './features/authentication/authenticationContext'
import OnboardingContextProvider from './features/onboarding/onboardingContext'
import AdminContextProvider from './features/admin/adminContext'
import GroupContextProvider from './features/groups/groupContext'
import ScrollToTop from './features/common/ScrollToTop'
import ScrollToHashElement from './utils/scrollToElement'

import './index.css'
import { colors, borderRadius, boxShadow } from './styles/styleGuide'

Sentry.init({
  dsn: 'https://e9ac63ff824742ebb26a43fc6a0ad2ef@o1153581.ingest.sentry.io/6232787',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.0,
})

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (color) => augmentColor({ color: { main: color } })

// // Font weights are defined here instead of styleGuide.js because in designs it depend on both typography and weight type
const fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
}

const typography = {
  paragraph01: {
    fontFamily: 'Figtree, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
    color: colors.neutral[900],
  },
}

const theme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
  zIndex: {
    modal: 1400,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: borderRadius.sm,
          padding: '8px 16px',
          boxShadow: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryBlue[500],
          '& .MuiTableCell-head': {
            // also set background color at the table cell level, otherwise the sticky header styling will override it
            // still need to set it at the head level, otherwise you see faint lines between the cells
            backgroundColor: colors.primaryBlue[500],
            color: colors.shades[0],
            fontWeight: 600,
            textWrap: 'nowrap',
            '&:first-child': {
              borderTopLeftRadius: borderRadius.xs,
            },
            '&:last-child': {
              borderTopRightRadius: borderRadius.xs,
            },
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '& .MuiTableSortLabel-icon': {
            opacity: 0.3,
          },
          '&.MuiTableSortLabel-active': {
            opacity: 1,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.neutral[100]}`,
          fontSize: '0.875rem',
          padding: '10px 6px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.neutral[50],
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          // position: 'sticky',
          // bottom: 0,
          // left: 0,
          backgroundColor: colors.neutral[50],
          boxShadow: `0px -2px 4px ${colors.neutral[50]}`,
          '& .MuiTablePagination-spacer': {
            flex: 'none',
            width: 0, // Set width to 0 to disable the spacer
          },
          '& .MuiToolbar-gutters': {
            padding: '0 24px',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: colors.shades[0],
          '& .MuiLinearProgress-bar': {
            backgroundColor: colors.primaryBlue[500],
          },
        },
        colorError: {
          backgroundColor: colors.shades[0],
          '& .MuiLinearProgress-bar': {
            backgroundColor: colors.error[600],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: borderRadius.xxs,
          backgroundColor: colors.shades[0],
          '& .MuiAlert-message': {
            ...typography.paragraph01,
            fontWeight: 600,
          },
        },
      },
      variants: [
        {
          props: { variant: 'greyWarning' },
          style: {
            '& .MuiAlert-message': {
              color: colors.neutral[500],
            },
            borderColor: colors.neutral[100],
          },
        },
        {
          props: { variant: 'subtleWarning' },
          style: {
            '& .MuiAlert-message': {
              color: colors.neutral[500],
            },
            borderColor: colors.neutral[200],
            backgroundColor: colors.neutral[50],
          },
        },
        {
          props: { variant: 'whiteWarning' },
          style: {
            '& .MuiAlert-message': {
              color: colors.neutral[500],
            },
            borderColor: colors.neutral[100],
          },
        },
        {
          props: { variant: 'blueWarningFilled' },
          style: {
            borderColor: colors.primaryBlue[600],
            backgroundColor: colors.primaryBlue[50],
          },
        },
        {
          props: { variant: 'blueWarning' },
          style: {
            '& .MuiAlert-message': {
              color: colors.primaryBlue[500],
            },
            borderColor: colors.primaryBlue[500],
          },
        },
        {
          props: { variant: 'orangeWarning' },
          style: {
            '& .MuiAlert-message': {
              color: colors.primaryOrange[500],
            },
            borderColor: colors.primaryOrange[500],
          },
        },
        {
          props: { variant: 'redWarning' }, // Red alert in designs
          style: {
            '& .MuiAlert-message': {
              color: colors.error[600],
            },
            borderColor: colors.error[600],
          },
        },
        {
          props: { variant: 'error' }, // Red warning in designs
          style: {
            '& .MuiAlert-message': {
              color: colors.error[600],
            },
            borderColor: colors.error[600],
          },
        },
        {
          props: { variant: 'success' }, // Green warning in designs
          style: {
            '& .MuiAlert-message': {
              color: colors.success[600],
            },
            borderColor: colors.success[600],
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      lightest: colors.primaryOrange[50],
      extraLight: colors.primaryOrange[100],
      veryLight: colors.primaryOrange[200],
      light: colors.primaryOrange[300],
      semiLight: colors.primaryOrange[400],
      main: colors.primaryOrange[500],
      semiDark: colors.primaryOrange[600],
      dark: colors.primaryOrange[700],
      veryDark: colors.primaryOrange[800],
      darkest: colors.primaryOrange[900],
    },
    secondary: {
      lightest: colors.primaryBlue[50],
      extraLight: colors.primaryBlue[100],
      veryLight: colors.primaryBlue[200],
      light: colors.primaryBlue[300],
      semiLight: colors.primaryBlue[400],
      main: colors.primaryBlue[500],
      semiDark: colors.primaryBlue[600],
      dark: colors.primaryBlue[700],
      veryDark: colors.primaryBlue[800],
      darkest: colors.primaryBlue[900],
    },
    tertiary: {
      main: colors.neutral[700],
    },
    neutral: {
      lightest: colors.neutral[50],
      extraLight: colors.neutral[100],
      veryLight: colors.neutral[200],
      light: colors.neutral[300],
      semiLight: colors.neutral[400],
      main: colors.neutral[500],
      semiDark: colors.neutral[600],
      dark: colors.neutral[700],
      veryDark: colors.neutral[800],
      darkest: colors.neutral[900],
    },
    base: {
      white: colors.shades[0],
      black: colors.shades[100],
    },
    border: {
      lighter: colors.neutral[50],
      light: colors.neutral[100],
      main: colors.neutral[200],
    },
    surface: {
      subtle: colors.neutral[50],
    },
    text: {
      disabled: colors.neutral[400],
      hint: colors.neutral[500],
      navText: colors.neutral[600],
      inputText: colors.neutral[800],
      link: colors.primaryBlue[500],
    },
    table: {
      selectedRowPrimary: colors.accentYellow[200],
      selectedRowSecondary: colors.accentYellow[100],
      hoverRow: colors.neutral[50],
    },
    success: {
      lightest: colors.success[50],
      extraLight: colors.success[100],
      veryLight: colors.success[200],
      light: colors.success[300],
      semiLight: colors.success[400],
      main: colors.success[500],
      semiDark: colors.success[600],
      dark: colors.success[700],
      veryDark: colors.success[800],
      darkest: colors.success[900],
    },
    error: {
      lightest: colors.error[50],
      extraLight: colors.error[100],
      veryLight: colors.error[200],
      light: colors.error[300],
      semiLight: colors.error[400],
      main: colors.error[500],
      semiDark: colors.error[600],
      dark: colors.error[700],
      veryDark: colors.error[800],
      darkest: colors.error[900],
    },
    purple: {
      lightest: colors.tags.purple[50],
      main: colors.tags.purple[500],
    },
    pink: {
      lightest: colors.tags.pink[50],
      main: colors.tags.pink[500],
    },
    yellow: {
      lightest: colors.accentYellow[50],
      extraLight: colors.accentYellow[100],
      veryLight: colors.accentYellow[200],
      light: colors.accentYellow[300],
      semiLight: colors.accentYellow[400],
      main: colors.accentYellow[500],
      semiDark: colors.accentYellow[600],
      dark: colors.accentYellow[700],
      veryDark: colors.accentYellow[800],
      darkest: colors.accentYellow[900],
    },
    emojis: {
      ...colors.emojis,
    },

    // TODO: Get rid of the colors below once we have built out the new components
    tabBlue: {
      main: '#1197CA',
    },
    red: {
      main: '#EA3A3D',
      light: '#FBD7D8',
    },
    eventReport: {
      circleChartBlue: '#178ECB',
      circleChartLightBlue: '#60A3DF',
      circleChartGrey: '#585758',
      circleChartLightGrey: '#A7A7A7',
    },
    profileWidget: {
      lightBlue: '#4EAFCB',
      teal: '#5CC7C2',
      green: '#7BDDAB',
      chartValuable: '#368BA3',
      chartReattendPYes: '#459693',
      chartLocationVirtual: '#2B7C53',
      linearProgressGray: '#555555',
    },
    nav: {
      border: '#E3E7EE',
      expandIcon: '#dbe3e8',
      svgFill: 'rgb(56, 68, 85)',
      impersonation: {
        background: 'red',
      },
    },
    black: createColor('#000000'),
    white: createColor('#FFFFFF'),
    greyExtraLight: createColor('#EEEEEE'),
    teal: createColor('#27D79E'),
  },
  // END OF PALETTE
  shape: {
    borderRadius: {
      ...borderRadius,
    },
    boxShadow: {
      ...boxShadow,
    },
  },
  fontWeights,
})

theme.typography.display1 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '4.5rem',
  fontWeight: 600,
  lineHeight: 1.11,
  letterSpacing: '-0.04em',
  color: colors.neutral[900],
}

theme.typography.display2 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '3.75rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: '-0.04em',
  color: colors.neutral[900],
}

theme.typography.h1 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '3rem',
  fontWeight: 600,
  lineHeight: 1.17,
  letterSpacing: '-0.04em',
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.125rem',
  },
}

theme.typography.h2 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '2.4375rem',
  fontWeight: 600,
  lineHeight: 1.2,
  letterSpacing: '-0.02em',
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.0625rem',
  },
}

theme.typography.h3 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.125,
  letterSpacing: '-0.02em',
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
}

theme.typography.h4 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.75rem',
  fontWeight: 600,
  lineHeight: 1.214,
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4375rem',
  },
}

theme.typography.h5 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.375rem',
  fontWeight: 700,
  lineHeight: 1.36,
  letterSpacing: '-0.02em',
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
}

theme.typography.h6 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.125rem',
  fontWeight: 700,
  lineHeight: 1.28,
  letterSpacing: '-0.02em',
  color: colors.neutral[900],
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}

// Subheading in the style guide
theme.typography.subtitle1 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.25rem',
  fontWeight: 400,
  lineHeight: 1.2,
  color: colors.neutral[900],
}

// Paragraph 01 in the style guide
theme.typography.body1 = {
  ...typography.paragraph01,
}

// Paragraph 02 in the style guide
theme.typography.body2 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.1875,
  color: colors.neutral[900],
}

theme.typography.paragraph00 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 1.17,
  color: colors.neutral[800],
}

theme.typography.paragraph03 = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '1.125rem',
  fontWeight: 400,
  lineHeight: 1.22,
  color: colors.neutral[900],
}

theme.typography.conversation = {
  fontFamily: 'CaveatBrush, cursive',
  fontSize: '1.125rem',
  fontWeight: 400,
  lineHeight: 1.2,
  color: colors.neutral[900],
}

theme.typography.caption = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 1.17,
  color: colors.neutral[900],
}

theme.typography.footer = {
  fontFamily: 'Figtree, sans-serif',
  fontSize: '0.833rem',
  fontWeight: 400,
  lineHeight: 1.2,
  color: colors.neutral[900],
}

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <AuthenticationContextProvider>
        <GroupContextProvider>
          <AdminContextProvider>
            <OnboardingContextProvider>
              <ScrollToTop />
              <ScrollToHashElement />
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </OnboardingContextProvider>
          </AdminContextProvider>
        </GroupContextProvider>
      </AuthenticationContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
