import { styled } from '@mui/system'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const MaxWidthContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noNavPage' && prop !== 'maxWidth',
})(({ noNavPage, maxWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: noNavPage ? 'center' : '',
  justifyContent: noNavPage ? 'center' : '',
  width: '100%',
  height: noNavPage ? '100vh' : '',
  maxWidth: maxWidth ? `${maxWidth}px` : '1280px',
}))

const PageContainer = ({ children, maxWidth, noNavPage = false }) => {
  return (
    <Container>
      <MaxWidthContainer noNavPage={noNavPage} maxWidth={maxWidth}>
        {children}
      </MaxWidthContainer>
    </Container>
  )
}

export default PageContainer
