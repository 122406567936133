import { useEffect, useContext, useState } from 'react'
import { Dialog, useMediaQuery, useTheme, Box, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as PlusIcon } from '../../icons/plusIcon.svg'
import { ReactComponent as TotalFeedbackIcon } from '../../icons/smileIcon_36x36.svg'
import { ReactComponent as TotalTalksIcon } from '../../icons/microPhoneIcon_36x36.svg'
import { ReactComponent as RevenueYearIcon } from '../../icons/creditCardIcon_36x36.svg'
import { ReactComponent as RevenueAllTimeIcon } from '../../icons/awardIcon_36x36.svg'

import Api from '../../services/api'
import { trackFirstEventCreated } from '../../services/metrics'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import { AdminContext } from '../admin/adminContext'
import { formatDate, processWithFallback } from '../common/helpers'
import { renderCurrency } from '../../utils/number'

import TalksTable from './TalksTable'
import PageContainer from '../common/PageContainer'
import PageHeader from '../common/PageHeader'
import StatsSummaryHeader from '../common/StatsSummaryHeader'
import TableActions from '../common/TableActions'
import ConfirmDialog from '../common/ConfirmDialog'
import EventFormParent from '../events/EventFormParent'
import { Notification } from '../common/Notification'

import moment from 'moment'

const initialEventState = {
  id: '',
  name: '',
  talkTitle: '',
  inPerson: '',
  virtual: '',
  eventOrganization: '',
  startTime: '',
  duration: '',
  date: '',
  compensation: '',
}

const initialConfirmProps = {
  title: '',
  onConfirm: () => {},
  onCancel: () => {},
  buttonColor: '',
  buttonText: '',
  cancelButtonText: '',
  manualConfirm: false,
  columnLayout: false,
  confirmText: '',
  children: '',
}

const TalksParent = () => {
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(true)
  const [event, setEvent] = useState(initialEventState)
  const [eventFormOpen, setEventFormOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    authData: { user },
    setNotification,
    showFullPageLoader,
    hideFullPageLoader,
    updateCounter,
  } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const {
    adminState: { impersonation },
  } = useContext(AdminContext)
  const { isTrialing, shouldShowUpgrades } = user.membership

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await Api.loadEvents()

        if (!res.errors) {
          const filteredEvents = res.filter((event) => shouldShowEvent(event))
          setEventsLoading(false)
          setEvents(filteredEvents)
        } else {
          throw res.errors
        }
      } catch (err) {
        setEventsLoading(false)
        return setNotification(err, 'error')
      }
    }

    fetchEvents()
  }, [])

  useEffect(() => {
    if (!eventsLoading) {
      const updatedCount = events.filter(
        (event) => event.isOwner && event.unviewedSubmissionCount > 0
      ).length

      updateCounter({ unviewedEvents: updatedCount })
    }
  }, [events, eventsLoading])

  const enoughResponses = (event) => {
    return event.responses > 0
  }

  const validMetaData = (event) => {
    return event.talkTitle || event.organization
  }

  const isFutureEvent = (event) => {
    return moment(event.date) > moment().add(-1, 'days')
  }

  const shouldShowEvent = (event) => {
    // Note:
    // This function needs to remain backwards compatible from before we
    // added the ability to track if an event is system generated or not

    // Hide any system generated event without enough responses
    if (event.isSystemGenerated && !enoughResponses(event)) {
      return false
    }

    return (
      enoughResponses(event) || validMetaData(event) || isFutureEvent(event)
    )
  }

  const primeEvent = events?.find((event) => event.isPrimeEvent)
  const totalFeedback = events?.reduce((acc, event) => acc + event.responses, 0)
  const totalTalks = events?.length

  const revenueThisYear = events
    ?.filter((event) => moment(event.date) > moment().startOf('year'))
    .reduce((acc, event) => acc + event.compensation, 0)

  const revenueAllTime = events?.reduce(
    (acc, event) => acc + event.compensation,
    0
  )

  const mergableEvents = events?.filter(
    (event) => event.isSelected && !event.isPrimeEvent
  )

  const handleOpenEventForm = (clickedEvent) => {
    if (clickedEvent) {
      setEvent(clickedEvent)
    }

    setEventFormOpen(true)
  }

  const handleCloseEventForm = () => {
    setEventFormOpen(false)
    setEvent(initialEventState)
  }

  const createEvent = async (params) => {
    try {
      const res = await Api.createEvent(params)

      if (res.errors) {
        throw res.errors
      } else {
        if (events?.length === 0) {
          const params = {
            user_id: user?.id,
            user_email: user?.email,
          }
          trackFirstEventCreated(params)
        }

        setEvents([res, ...events])
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const editEvent = async (eventId, params) => {
    try {
      const res = await Api.editEvent(eventId, params)

      if (res.errors) {
        throw res.errors
      } else {
        setEvents(events.map((event) => (event.id === res.id ? res : event)))
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const handleMarkEventAsViewed = async (event) => {
    if (event.unviewedSubmissionCount === 0) return

    await editEvent(event.id, { new_submissions: 0 })
  }

  const handleConfirmDelete = async (eventToDelete) => {
    if (eventToDelete) {
      try {
        let res
        if (selectedGroup) {
          res = await Api.deleteGroupEvent(eventToDelete.id, selectedGroup.id)
        } else {
          res = await Api.deleteEvent(eventToDelete.id)
        }

        if (!res.errors) {
          setNotification('Delete Success!', 'success')
          setEvents(events.filter((event) => event.id !== eventToDelete.id))
        } else {
          throw res.errors
        }
      } catch (err) {
        setNotification(err, 'error')
      }
    }
  }

  const handleConfirmMergeEvents = async () => {
    try {
      showFullPageLoader()

      const params = {
        event_merges: {
          event_ids: mergableEvents.map((event) => event.id),
          prime_event_id: primeEvent.id,
        },
        ...(selectedGroup ? { group_id: selectedGroup.id } : {}),
      }

      let res
      if (selectedGroup) {
        res = await Api.mergeGroupEvent(params)
      } else {
        res = await Api.mergeEvent(params)
      }

      if (res.errors) {
        throw res.errors
      } else {
        const filteredEvents = res.filter((event) => shouldShowEvent(event))
        setNotification('Event Merge Successful!', 'success')
        setEvents(filteredEvents)
        hideFullPageLoader()
      }
    } catch (err) {
      hideFullPageLoader()
      setNotification(err, 'error')
    }
  }

  const selectEvent = (eventToSelect) => {
    // If there is no event selected, set this one to be event prime
    if (!events.some((event) => event.isPrimeEvent)) {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isPrimeEvent: true, isSelected: true }
            : { ...event }
        )
      )
    } else {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isSelected: true }
            : { ...event }
        )
      )
    }
  }

  const deselectAllEvents = () => {
    // Make sure to set isPrimeEvent to false as well
    setEvents(
      events.map(
        (event) => event && { ...event, isSelected: false, isPrimeEvent: false }
      )
    )
  }

  const deselectEvent = (eventToSelect) => {
    // If you deselect the prime event, deselect all of them
    if (eventToSelect.isPrimeEvent) {
      deselectAllEvents()
    } else {
      setEvents(
        events.map((event) =>
          eventToSelect.id === event.id
            ? { ...event, isSelected: false }
            : { ...event }
        )
      )
    }
  }

  const handleMergeEvents = async () => {
    await handleConfirmMergeEvents()
    setConfirmOpen(false)
  }

  const mergeConfirmContent = () => (
    <span>
      Are you sure you want to merge these events?{' '}
      <strong>
        Merging {mergableEvents.length} event
        {mergableEvents?.length > 1 ? 's' : ''} into{' '}
        {formatDate(primeEvent.date, primeEvent.utcOffset)}{' '}
        {primeEvent.talkTitle ? `- ${primeEvent.talkTitle}` : ''} is NOT
        reversible.
      </strong>
    </span>
  )

  const handleMergeEventsClick = () => {
    if (mergableEvents.length > 0) {
      setConfirmProps({
        title: 'Merge Events?',
        buttonColor: 'black',
        children: mergeConfirmContent(),
        onConfirm: () => handleMergeEvents(),
      })

      setConfirmOpen(true)
    }
  }

  const handleDeleteEvent = (eventToDelete) => {
    setConfirmProps({
      title: 'Are you sure you want to delete this event?',
      onConfirm: () => handleConfirmDelete(eventToDelete),
      buttonColor: 'black',
      buttonText:
        'I understand the consequences, delete this event and related data',
      cancelButtonText: 'Cancel request, keep this event',
      manualConfirm: true,
      columnLayout: true,
      confirmText: 'DELETE ALL',
      children:
        eventToDelete?.responses > 0 || eventToDelete?.leads > 0 ? (
          <>
            You will also be deleting{' '}
            {eventToDelete?.responses > 0
              ? `${eventToDelete.responses} responses`
              : ''}
            {eventToDelete?.leads > 0
              ? ` and ${eventToDelete.leads} leads`
              : ''}{' '}
            related to{' '}
            {eventToDelete?.talkTitle ? (
              <>"{eventToDelete.talkTitle}"</>
            ) : (
              'this event'
            )}{' '}
            on {formatDate(eventToDelete.date, eventToDelete.offset)}.
          </>
        ) : (
          <></>
        ),
    })
    setConfirmOpen(true)
  }

  const statsSummaryItems = [
    {
      label: 'Total Feedback',
      icon: <TotalFeedbackIcon />,
      svgType: 'stroke',
      value: processWithFallback(totalFeedback),
    },
    {
      label: 'Total Talks',
      icon: <TotalTalksIcon />,
      svgType: 'stroke',
      value: processWithFallback(totalTalks),
    },
    // {
    //   label: 'Upcoming Talks',
    //   value: events?.filter((event) => moment(event.date) > moment()).length,
    // },
    {
      label: 'Talks Booked This Year',
      icon: <RevenueYearIcon />,
      svgType: 'stroke',
      value: processWithFallback(
        revenueThisYear,
        { fallback: '$-' },
        renderCurrency
      ),
    },
    {
      label: 'Talks Booked All Time',
      icon: <RevenueAllTimeIcon />,
      svgType: 'stroke',
      value: processWithFallback(
        revenueAllTime,
        { fallback: '$-' },
        renderCurrency
      ),
    },
  ]

  return (
    <PageContainer>
      <PageHeader
        header="Your Talks"
        showUpgradeButton={isTrialing || shouldShowUpgrades}
      />
      {!eventsLoading && !events?.find((event) => enoughResponses(event)) && (
        <Notification hideClose={true} variant="whiteWarning">
          Once you start using your <Link to="/codes">Talkadot Codes</Link>,
          your talk data will automatically appear here.
        </Notification>
      )}
      <StatsSummaryHeader statItems={statsSummaryItems} />
      <TableActions
        ctaOpts={{
          onClick: handleOpenEventForm,
          text: 'Add an upcoming talk',
          icon: <PlusIcon />,
        }}
      />
      <TalksTable
        events={events}
        loading={eventsLoading}
        handleOpenEventForm={handleOpenEventForm}
        handleDeleteEvent={handleDeleteEvent}
        handleMergeEventsClick={handleMergeEventsClick}
        handleMarkEventAsViewed={handleMarkEventAsViewed}
        mergableEvents={mergableEvents}
        selectEvent={selectEvent}
        deselectEvent={deselectEvent}
      />
      <Dialog
        open={eventFormOpen}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={() => handleCloseEventForm()}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => handleCloseEventForm()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <EventFormParent
          eventInitialState={event}
          handleClose={handleCloseEventForm}
          editEvent={editEvent}
          createEvent={createEvent}
        />
      </Dialog>
      <ConfirmDialog
        title={confirmProps.title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmProps.onConfirm}
        onCancel={confirmProps.onCancel}
        buttonColor={confirmProps.buttonColor}
        buttonText={confirmProps.buttonText}
        cancelButtonText={confirmProps.cancelButtonText}
        manualConfirm={confirmProps.manualConfirm}
        columnLayout={confirmProps.columnLayout}
        confirmText={confirmProps.confirmText}>
        {confirmProps.children}
      </ConfirmDialog>
    </PageContainer>
  )
}

export default TalksParent
