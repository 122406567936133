import { styled } from '@mui/system'
import { useTheme, Typography, Tooltip } from '@mui/material'

import { ReactComponent as DragIcon } from '../../../icons/burgerIcon_16x16.svg'
import { ReactComponent as CustomSettingsIcon } from '../../../icons/customSettingsIcon_16x16.svg'
import { ReactComponent as LinkIcon } from '../../../icons/linkIcon_16x16.svg'
import { ReactComponent as BonusIcon } from '../../../icons/bonusIcon_16x16.svg'
import { ReactComponent as OpenEndedIcon } from '../../../icons/questionTypeIcons/freeFlow.svg'
import { ReactComponent as BooleanIcon } from '../../../icons/questionTypeIcons/boolean.svg'
import { ReactComponent as MultipleChoiceIcon } from '../../../icons/questionTypeIcons/multipleChoice.svg'
import { ReactComponent as EmojiIcon } from '../../../icons/questionTypeIcons/emoji.svg'
import { ReactComponent as NpsIcon } from '../../../icons/questionTypeIcons/nps.svg'

import CodeBuilderToggleQuestionButton from './CodeBuilderToggleQuestionButton'
import CodeBuilderDeleteButton from './CodeBuilderDeleteButton'
import DisabledBadgeIconWrapper from '../../common/DisabledBadgeIconWrapper'

import { usePermissionHelper } from '../../../utils/permission_helper'

import { useSortable } from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'

const QuestionItemContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'isGroupChild' && prop !== 'disabled',
})(({ theme, selected, isGroupChild, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: theme.spacing(5.5),
  padding: theme.spacing(0, 1.75),
  marginLeft: isGroupChild ? theme.spacing(4) : 0,
  backgroundColor: selected
    ? theme.palette.neutral.main
    : theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.border.light}`,
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
}))

const DragHandleContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})(({ theme, isDragging }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(1.75),
  minWidth: '28px',
  cursor: isDragging ? 'grabbing' : 'grab',
}))

const StyledDragIcon = styled(DragIcon)(({ theme }) => ({
  path: {
    fill: theme.palette.text.disabled,
  },
}))

const DragHandlePlaceholder = styled('div')(({ theme }) => ({
  width: '28px',
  height: '100%',
  paddingRight: theme.spacing(1.75),
}))

const StyledCustomizedIcon = styled(CustomSettingsIcon, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  path: {
    stroke: selected
      ? theme.palette.text.disabled
      : theme.palette.secondary.main,
  },
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  flex: 1,
}))

const BonusIconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'isMissingValue',
})(({ theme, selected, isMissingValue }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(1),
  'svg:not(.disabled-badge)': {
    path: {
      stroke: isMissingValue
        ? theme.palette.error.main
        : selected
        ? theme.palette.base.white
        : theme.palette.neutral.light,
    },
  },
}))

const CustomIconWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'isMissingValue' && prop !== 'questionType',
})(({ theme, selected, isMissingValue, questionType }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    path: {
      stroke:
        questionType !== 'BOOLEAN'
          ? isMissingValue
            ? theme.palette.error.main
            : selected
            ? theme.palette.base.white
            : theme.palette.secondary.main
          : '',
      fill:
        questionType === 'BOOLEAN'
          ? isMissingValue
            ? theme.palette.error.main
            : selected
            ? theme.palette.base.white
            : theme.palette.secondary.main
          : '',
    },
    rect: {
      stroke: isMissingValue
        ? theme.palette.error.main
        : selected
        ? theme.palette.base.white
        : theme.palette.secondary.main,
    },
    line: {
      stroke: isMissingValue
        ? theme.palette.error.main
        : selected
        ? theme.palette.base.white
        : theme.palette.secondary.main,
    },
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})(({ theme, textColor }) => ({
  color: textColor,
  fontWeight: theme.fontWeights.semiBold,
  paddingLeft: theme.spacing(1),
}))

const NoDropOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0.5,
  backgroundColor: theme.palette.error.semiDark,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  zIndex: 10,
}))

const CodeBuilderPreviewQuestionItem = ({
  question,
  surveyOffer,
  selected,
  movable,
  canToggleEnable = false,
  disabled = false,
  setCurrentQuestionId,
  questionNumber,
  updateQuestion,
  handleDeleteQuestion,
  isGroupChild,
  tooltipText,
  canReorderQuestions,
  isDropAllowed,
}) => {
  const theme = useTheme()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    // transition,
    active,
    over,
  } = useSortable({ id: question.id })

  const { canAccessAction } = usePermissionHelper()

  const handleClick = () => {
    if (disabled || selected) {
      return
    }

    setCurrentQuestionId(question.id)
  }

  const updateQuestionEnabled = (questionId) => {
    updateQuestion(questionId, { enabled: !question.enabled })
  }

  const renderPreviewContent = () => {
    const content = `${questionNumber}. ${question.config?.previewName}`

    if (
      question.questionType === 'EMOJI_SCALE' &&
      question.config.isTalkadotQuestion
    ) {
      return `${content}: ${question.replacementValue}`
    }

    // For custom questions, show a truncated version of the
    // question content in the preview
    if (!question.config.isTalkadotQuestion && question.content) {
      return `${content} ${question.content?.substring(0, 10) + '...'}`
    }

    return content
  }

  const isBeingHovered = over?.id === question.id
  const showNoDropOverlay = isBeingHovered && !isDropAllowed
  const isDragging = active?.id === question.id

  const renderDragableIcon = () => {
    if (movable) {
      if (canReorderQuestions()) {
        return (
          <DragHandleContainer
            selected={selected}
            isDragging={isDragging}
            {...listeners}>
            {<StyledDragIcon />}
          </DragHandleContainer>
        )
      }

      return (
        <Tooltip
          title="Upgrade your plan to reorder questions"
          disableInteractive
          enterTouchDelay={0}
          leaveTouchDelay={3000}>
          <DragHandleContainer selected={selected}>
            {<StyledDragIcon />}
          </DragHandleContainer>
        </Tooltip>
      )
    }

    if (!isGroupChild) {
      return <DragHandlePlaceholder />
    }
  }

  const renderSecondaryBonusIcons = (selected) => {
    const { selectedBonusType, fileKey, link } = surveyOffer || {}

    const renderIconByType = ({
      icon,
      isMissingValue,
      selected,
      disabledIcon = false,
      tooltipContent,
    }) => (
      <Tooltip
        title={tooltipContent}
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <BonusIconWrapper selected={selected} isMissingValue={isMissingValue}>
          {disabledIcon ? (
            <DisabledBadgeIconWrapper>{icon}</DisabledBadgeIconWrapper>
          ) : (
            icon
          )}
        </BonusIconWrapper>
      </Tooltip>
    )

    switch (selectedBonusType) {
      case 'file': {
        const isMissingValue = !fileKey

        const tooltipContent = isMissingValue
          ? 'Missing bonus file'
          : 'Bonus file uploaded'
        return renderIconByType({
          icon: <BonusIcon />,
          isMissingValue,
          selected,
          tooltipContent,
        })
      }
      case 'link': {
        const missingValue = !link
        const tooltipContent = missingValue
          ? 'Missing bonus link'
          : 'Bonus link uploaded'
        return renderIconByType({
          icon: <LinkIcon />,
          missingValue,
          selected,
          tooltipContent,
        })
      }
      case 'none': {
        const tooltipContent = 'You have selected not to offer a bonus'
        return renderIconByType({
          icon: <BonusIcon />,
          isMissingValue: false,
          selected,
          tooltipContent,
          disabledIcon: true,
        })
      }
      default:
        return null
    }
  }

  const renderSecondaryCustomQuestionIcons = (selected) => {
    const { questionType } = question

    const renderIconByType = ({
      icon,
      isMissingValue,
      selected,
      tooltipContent,
    }) => (
      <Tooltip
        title={tooltipContent}
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <CustomIconWrapper
          selected={selected}
          isMissingValue={isMissingValue}
          questionType={questionType}>
          {icon}
        </CustomIconWrapper>
      </Tooltip>
    )

    switch (questionType) {
      case 'FREE_FLOW': {
        return renderIconByType({
          icon: <OpenEndedIcon />,
          isMissingValue: !question.content,
          selected,
          tooltipContent: 'Open-ended question',
        })
      }
      case 'BOOLEAN': {
        return renderIconByType({
          icon: <BooleanIcon />,
          isMissingValue: !question.content,
          selected,
          tooltipContent: 'Yes/No question',
        })
      }
      case 'EMOJI_SCALE': {
        return renderIconByType({
          icon: <EmojiIcon />,
          isMissingValue: !question.content,
          selected,
          tooltipContent: '5 point scale question',
        })
      }
      case 'MULTIPLE_CHOICE': {
        return renderIconByType({
          icon: <MultipleChoiceIcon />,
          isMissingValue: !question.content,
          selected,
          tooltipContent: 'Multiple choice question',
        })
      }
      case 'NPS': {
        return renderIconByType({
          icon: <NpsIcon />,
          isMissingValue: !question.content,
          selected,
          tooltipContent: 'Net Promoter Score® question',
        })
      }
      default:
        return null
    }
  }

  const renderSecondaryIcons = (selected) => {
    if (question.questionType === 'bonus') {
      return renderSecondaryBonusIcons(selected)
    }

    if (!question.config.isTalkadotQuestion) {
      return renderSecondaryCustomQuestionIcons(selected)
    }
  }

  const textColor = ({ question, selected }) => {
    if (question.content?.trim() === '') {
      return theme.palette.error.main
    } else if (selected) {
      return theme.palette.base.white
    } else if (
      (question.hasBeenCustomized || !question.config.isTalkadotQuestion) &&
      // exclude code, bonus, and thank you questions
      typeof question.id === 'number'
    ) {
      return theme.palette.secondary.main
    } else {
      return theme.palette.text.disabled
    }
  }

  return (
    <Tooltip
      title={tooltipText}
      placement="left"
      enterTouchDelay={0}
      leaveTouchDelay={3000}>
      <QuestionItemContainer
        selected={selected}
        onClick={handleClick}
        isGroupChild={isGroupChild}
        disabled={disabled}
        ref={setNodeRef}
        {...attributes}
        style={{
          transform: CSS.Transform.toString(transform),
        }}>
        {showNoDropOverlay && (
          <NoDropOverlay>🚫 Cannot Drop Here</NoDropOverlay>
        )}
        {renderDragableIcon()}
        <ContentWrapper>
          <StyledTypography
            variant="body1"
            textColor={textColor({ question, selected })}>
            {renderPreviewContent()}
          </StyledTypography>
          {renderSecondaryIcons(selected)}
        </ContentWrapper>
        {question.hasBeenCustomized && question.config.isTalkadotQuestion && (
          <Tooltip
            title="This question has been customized from the default settings"
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <StyledCustomizedIcon selected={selected} />
          </Tooltip>
        )}
        {canToggleEnable && (
          <CodeBuilderToggleQuestionButton
            handleToggleQuestion={() => updateQuestionEnabled(question.id)}
            enabled={question.enabled}
            iconColor={theme.palette.text.disabled}
            canToggleVisibility={canAccessAction(
              question.config.toggleEnabledTier
            )}
            toggleEnabledTier={question.config.toggleEnabledTier}
            question={question}
          />
        )}
        {question.config?.canDelete && (
          <CodeBuilderDeleteButton
            handleDelete={() => handleDeleteQuestion(question.id)}
            iconColor={theme.palette.text.disabled}
          />
        )}
      </QuestionItemContainer>
    </Tooltip>
  )
}

export default CodeBuilderPreviewQuestionItem
