export const colors = {
  neutral: {
    50: '#F7F8F9', // lightest
    100: '#E7EAEE', // extraLight
    200: '#D0D5DD', // veryLight
    300: '#B8C0CC', // light
    400: '#A0ABBB', // semiLight
    500: '#64748B', // main
    600: '#4B5768', // semiDark
    700: '#323A46', // dark
    800: '#191D23', // veryDark
    900: '#0D0F11', // darkest
  },
  primaryBlue: {
    50: '#E9F7FD',
    100: '#D2F0FB',
    200: '#90D9F6',
    300: '#4DC2F0',
    400: '#20B3EC',
    500: '#1197CB',
    600: '#0F84B2',
    700: '#0D7198',
    800: '#0B5E7F',
    900: '#06394C',
  },
  primaryOrange: {
    50: '#FFFAF2',
    100: '#FFF0D9',
    200: '#FFC266',
    300: '#FFA826',
    400: '#FF9E0D',
    500: '#FF9900',
    600: '#FC7E1E',
    700: '#D9640C',
    800: '#A74800',
    900: '#402600',
  },
  accentYellow: {
    50: '#FFF9EA',
    100: '#FFF4D5',
    200: '#FFE295',
    300: '#FFD155',
    400: '#FFC62B',
    500: '#FFBA00',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },
  success: {
    50: '#F0FBF1',
    100: '#E2F6E3',
    200: '#B5EAB9',
    300: '#98E19E',
    400: '#7AD982',
    500: '#4ECC58',
    600: '#2FA439',
    700: '#27892F',
    800: '#206D26',
    900: '#103713',
  },
  error: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  shades: {
    0: '#FFFFFF',
    5: '#FBFBFB',
    100: '#000000',
  },
  tags: {
    purple: {
      50: '#F2EDFD',
      500: '#895BF1',
    },
    pink: {
      50: '#FCE7F6',
      500: '#E74ABD',
    },
  },
  emojis: {
    red: '#E8354D',
    orange: '#FC7E1E',
    yellow: '#FFCE2B',
    lime: '#ACD91A',
    green: '#4ECC58',
    brightGreen: '#5CCC5B',
  },
}

export const borderRadius = {
  xxxs: '2px',
  xxs: '4px',
  xs: '6px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '20px',
  full: '50%',
}

export const boxShadow = {
  xs: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  sm: '0px 6px 12px 0px rgba(0, 0, 0, 0.10)',
  md: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
  lg: '0px 10px 20px 0px rgba(0, 0, 0, 0.10)',
  xl: '0px 12px 24px 0px rgba(0, 0, 0, 0.10)',
  xsInset:
    'inset 0px 4px 8px -4px rgba(0, 0, 0, 0.10), inset 0px -4px 8px -4px rgba(0, 0, 0, 0.10)',
}
