import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { Doughnut } from 'react-chartjs-2'

import { colors } from '../../styles/styleGuide'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4.5),
  flex: 1,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const DonutContainer = styled('div')(({ theme }) => ({
  width: '200px',
  height: '200px',
}))

const ProgressBarsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  flex: 1,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const ProgressBarsColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  flex: 1,
}))

const ProgressBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

const LabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})(({ theme, backgroundColor }) => ({
  height: '8px',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: theme.palette.neutral.veryLight,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '8px',
    backgroundColor: backgroundColor,
  },
}))

const CustomQuestionMultipleChoice = ({ question }) => {
  const selectedOptions = question.possible_answers
    .map((option) => {
      return {
        text: option,
        count: question.answers.filter((answer) => answer.text === option)
          .length,
        percentage: question.answers.length
          ? Math.round(
              (question.answers.filter((answer) => answer.text === option)
                .length /
                question.answers.length) *
                100
            )
          : 0,
      }
    })
    .filter((option) => option.text.trim().length > 0)
    .sort((a, b) => b.count - a.count)

  const distinctSelectedOptionCount = selectedOptions.filter(
    (option) => option.count > 0
  ).length

  const colorMap = selectedOptions.map((option, index) => {
    if (option.count > 0) {
      // Determine color step based on distinct option count
      const colorStep = distinctSelectedOptionCount <= 5 ? 200 : 100
      // Calculate shade index, ensuring it starts from 50 and doesn't go below it
      const shadeIndex = Math.max(50, 900 - index * colorStep)
      return colors.primaryBlue[shadeIndex]
    }
    return colors.neutral[200] // Default color if count is 0, doesn't get used
  })

  const chartData = {
    labels: selectedOptions.map((option) => option.text),
    datasets: [
      {
        data: selectedOptions.map((option) => option.count),
        backgroundColor: colorMap,
        borderWidth: 0,
        cutout: '70%',
      },
    ],
  }

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const renderProgressBar = (option, index) => {
    return (
      <ProgressBarContainer key={index}>
        <LabelContainer>
          <Typography variant="body1">{option.text}</Typography>
          <Typography variant="body1">{option.percentage}%</Typography>
        </LabelContainer>
        <StyledLinearProgress
          variant="determinate"
          backgroundColor={colorMap[index]}
          value={option.percentage}
        />
      </ProgressBarContainer>
    )
  }

  return (
    <Container>
      <DonutContainer>
        <Doughnut data={chartData} options={chartOptions} />
      </DonutContainer>
      <ProgressBarsContainer>
        <ProgressBarsColumn>
          {selectedOptions
            .slice(0, 5)
            .map((option, index) => renderProgressBar(option, index))}
        </ProgressBarsColumn>
        {selectedOptions.length > 5 && (
          <ProgressBarsColumn>
            {selectedOptions
              .slice(5, 10)
              .map((option, index) => renderProgressBar(option, index + 5))}
          </ProgressBarsColumn>
        )}
      </ProgressBarsContainer>
    </Container>
  )
}

export default CustomQuestionMultipleChoice
