import { useState } from 'react'

import { styled } from '@mui/system'

import { HeaderTabs } from "../LeadsTabs"
import { Typography } from '@mui/material'

import { OPT_IN, BOOKING, REFERRAL } from './EmailCampaignSettings'

const CampaignStatusIcon = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isTabActive' && prop !== 'isCampaignActive',
})(({ isTabActive, isCampaignActive, theme }) => ({
  backgroundColor: !isCampaignActive
    ? theme.palette.neutral.extraLight
    : isTabActive
    ? theme.palette.secondary.main
    : theme.palette.secondary.extraLight,
  borderRadius: '8px',
  padding: '6px 8px',
  color:
    isCampaignActive && !isTabActive
      ? theme.palette.secondary.main
      : theme.palette.base.white,
  fontWeight: 600,
}))

const EmailCampaignTabs = ({ setActiveTab, bookingCampaign, referralCampaign, optInCampaign }) => {
  const [value, setValue] = useState(0)

  const tabs = [
    {
      label: 'Booking Lead Follow Up',
      name: BOOKING,
      icon: (
        <CampaignStatusIcon
          isTabActive={value === 0}
          isCampaignActive={bookingCampaign?.isActive}>
          {bookingCampaign?.isActive ? 'ON' : 'OFF'}
        </CampaignStatusIcon>
      ),
    },
    {
      label: 'Referral Lead Follow Up',
      name: REFERRAL,
      icon: (
        <CampaignStatusIcon
          isTabActive={value === 1}
          isCampaignActive={referralCampaign?.isActive}>
          {referralCampaign?.isActive ? 'ON' : 'OFF'}
        </CampaignStatusIcon>
      ),
    },
    {
      label: 'Email Opt In Bonus Emails',
      name: OPT_IN,
      icon: (
        <CampaignStatusIcon
          isTabActive={value === 2}
          isCampaignActive={optInCampaign?.isActive}>
          {optInCampaign?.isActive ? 'ON' : 'OFF'}
        </CampaignStatusIcon>
      ),
    },
  ]


  const handleTabChange = (newValue) => {
    setValue(newValue)

    const activeTab = tabs[newValue].name
    setActiveTab(activeTab)
  }

  return(
    <HeaderTabs tabs={tabs} value={value} handleTabChange={handleTabChange} />
  )
}

export default EmailCampaignTabs