// This a custom hook to manage the front chat widget
// There is an official SDK that has some dependencies that
// didn't resolve with our code, so wrote this instead.
// See docs here:
// https://dev.frontapp.com/docs/chat-sdk-reference

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const EXCLUDED_ROUTES = ['/login', '/register', '/welcome']

export default function useFrontChat(user) {
  const location = useLocation()
  const [chatWidgetInitialized, setChatWidgetInitialized] = useState(false)

  const loadChatWidget = (options) => {
    // We currently only want to use chat for logged in users
    // Even though we don't show it on certain routes, this adds another
    // layer of protection for it
    if (!user?.id) {
      return
    }

    if (!chatWidgetInitialized) {
      if (window.FrontChat) {
        window.FrontChat('init', options)
        setChatWidgetInitialized(true)
      }
    }
  }

  useEffect(() => {
    const options = {
      chatId: process.env.REACT_APP_FRONT_CHAT_ID,
      useDefaultLauncher: true,
      email: user.email,
      userHash: user.frontLiveChatHash,
      name: `${user.first_name} ${user.last_name}`,
      customFields: {
        'Membership Plan': user.membership.plan.name,
      },
    }

    if (EXCLUDED_ROUTES.includes(location.pathname)) {
      window.FrontChat('shutdown')
      setChatWidgetInitialized(false)
    } else {
      // Load the widget once if not already loaded so it doesn't
      // appear and disapear each time we change the page
      if (!chatWidgetInitialized) {
        loadChatWidget(options)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    user.id,
    user.email,
    user.first_name,
    user.last_name,
    user.frontLiveChatHash,
  ])

  return null
}
