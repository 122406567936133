import { useState } from 'react'
import { styled } from '@mui/system'

import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  InputBase,
} from '@mui/material'

import { ReactComponent as OpenEndedIcon } from '../../../icons/questionTypeIcons/freeFlow.svg'
import { ReactComponent as BooleanIcon } from '../../../icons/questionTypeIcons/boolean.svg'
import { ReactComponent as MultipleChoiceIcon } from '../../../icons/questionTypeIcons/multipleChoice.svg'
import { ReactComponent as EmojiIcon } from '../../../icons/questionTypeIcons/emoji.svg'
import { ReactComponent as CaretDownIcon } from '../../../icons/caretDown_16x16.svg'
import { ReactComponent as NpsIcon } from '../../../icons/questionTypeIcons/nps.svg'

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'questionType' && prop !== 'epitome',
})(({ questionType, epitome, theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  minHeight: '52px !important',
  // NOTE: Not sure what this is for?
  // '& .MuiListItemIcon-root': {
  //   minWidth: '50px',
  // },
  backgroundColor: `${
    questionType === epitome && theme.palette.secondary.main
  } !important`,
  '&:hover': {
    backgroundColor: questionType === epitome && theme.palette.secondary.main,
  },
  '& svg path': {
    // Non-boolean type svgs need the stroke
    stroke:
      questionType === epitome && epitome !== 'BOOLEAN'
        ? theme.palette.base.white
        : '',
    // Only the boolean svg needs the fill
    fill:
      questionType === epitome && epitome === 'BOOLEAN'
        ? theme.palette.base.white
        : '',
  },
  '& svg rect': {
    stroke: questionType === epitome ? theme.palette.base.white : '',
  },
  '& svg line': {
    stroke: questionType === epitome ? theme.palette.base.white : '',
  },
  margin: '0 15px',
  borderRadius: theme.shape.borderRadius.xs,
}))

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  span: {
    color: selected && theme.palette.base.white,
  },
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: '100%',
    fontFamily: 'Figtree, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    color: theme.palette.neutral.main,
    textOverflow: 'ellipsis',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.neutral.light,
      opacity: '50%',
    },
  },
}))

const StyledCaretIcon = styled(CaretDownIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.neutral.dark,
  },
}))

export const renderStyledMenuItem = (questionType, question) => (
  <StyledMenuItem
    key={questionType.id}
    value={questionType.id}
    questionType={question.questionType}
    epitome={questionType.epitome}>
    <ListItemIcon>
      <QuestionTypeIcon questionType={questionType.epitome} />
    </ListItemIcon>
    <StyledListItemText
      selected={question.questionType === questionType.epitome}>
      {questionType.label}
    </StyledListItemText>
  </StyledMenuItem>
)

export const QuestionTypeIcon = ({ questionType }) => {
  if (questionType === 'FREE_FLOW') {
    return <OpenEndedIcon />
  }

  if (questionType === 'BOOLEAN') {
    return <BooleanIcon />
  }

  if (questionType === 'EMOJI_SCALE') {
    return <EmojiIcon />
  }

  if (questionType === 'MULTIPLE_CHOICE') {
    return <MultipleChoiceIcon />
  }

  if (questionType === 'NPS') {
    return <NpsIcon />
  }

  return <OpenEndedIcon />
}

const CodeBuilderQuestionTypePicker = ({
  availableQuestionTypes,
  changeQuestionType,
  question,
}) => {
  const [value, setValue] = useState('')

  const updateQuestionType = (questionTypeId) => {
    setValue(questionTypeId)
    changeQuestionType(question.id, { question_type_id: questionTypeId })
  }
  return (
    <Select
      id="question-type-picker-select"
      onChange={(e) => {
        updateQuestionType(e.target.value)
      }}
      MenuProps={{
        PaperProps: {
          style: {
            transformOrigin: 'right top',
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      input={<StyledInput />}
      value={value}
      renderValue={() => null}
      IconComponent={StyledCaretIcon}>
      {availableQuestionTypes?.map((questionType) => {
        return renderStyledMenuItem(questionType, question)
      })}
    </Select>
  )
}

export default CodeBuilderQuestionTypePicker
