import { styled } from '@mui/system'

import FormFieldError from './FormFieldError'

const Container = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'marginTop' && prop !== 'containerHeight',
})(({ marginTop, containerHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: marginTop || 0,
  height: containerHeight || '100%',
  width: '100%',
}))

const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const InputWrapper = styled('div')(({ theme, canEdit }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  height: '100%',
  backgroundColor: canEdit
    ? theme.palette.base.white
    : theme.palette.surface.subtle,
    borderRadius: theme.shape.borderRadius.xxs,
}))

export const TextInput = styled('input')(({ theme }) => ({
  width: '100%',
  transition: '300ms all ease-in-out',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: '400',
  fontSize: '0.875rem',
  border: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  color: theme.palette.neutral.main,
  textOverflow: 'ellipsis',
  padding: '1px 10px',
  height: '100%',
  minHeight: '48px',
  outline: 'none',
  borderRadius: theme.shape.borderRadius.xxs,
  '&:focus': {
    outline: `1px solid ${theme.palette.secondary.main}`,
  },
  '&::placeholder': {
    color: theme.palette.neutral.light,
    opacity: '50%',
  },
}))

const ErrorContainer = styled('div')({
  marginTop: '10px'
})

const InputSection = ({
  canEdit= true,
  showError = false,
  errorMsg = '',
  allowClose = false,
  children,
}) => (
  <Container>
    <InputContainer>
      <InputWrapper canEdit={canEdit}>
        {children}
      </InputWrapper>
    </InputContainer>
    {showError && errorMsg?.length > 0 && (
      <ErrorContainer>
        <FormFieldError errorMsg={errorMsg} allowClose={allowClose} />
      </ErrorContainer>
    )}
  </Container>
)

export default InputSection